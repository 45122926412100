.subscription-plans-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 40px 20px;
  background: #ffffff;
}

.subscription-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #558392;
}

.subscription-subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #4f6f52;
  margin-bottom: 40px;
}

.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.plan-card {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  position: relative;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  display: flex;
  flex-direction: column;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.popular-plan {
  border: 2px solid #4f6f52;
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #558392;
  color: white;
  font-size: 0.85rem;
  padding: 5px 15px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.plan-name {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #558392;
}

.plan-price {
  margin-bottom: 25px;
}

.price-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #4f6f52;
}

.custom-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4f6f52;
}

.price-period {
  font-size: 1rem;
  color: #666;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 0 0 30px 0;
  flex-grow: 1;
}

.plan-features li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 25px;
}

.plan-features li::before {
  content: '✓';
  color: #4f6f52;
  position: absolute;
  left: 0;
}

.signup-button {
  background: #2196f3;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(33, 150, 243, 0.3);
}

.signup-button:hover {
  background: #0b7dda;
  box-shadow: 0px 0px 15px rgba(33, 150, 243, 0.5);
}

.subscribe-button {
  background-color: orange;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(255, 165, 0, 0.3);
}

.subscribe-button:hover {
  background-color: #e69500;
  box-shadow: 0px 0px 15px rgba(255, 165, 0, 0.5);
}

.enterprise-section {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.enterprise-section h2 {
  margin-bottom: 15px;
  color: #558392;
}

.enterprise-section p {
  margin-bottom: 25px;
  color: #4f6f52;
}

.contact-button {
  display: inline-block;
  background: #558392;
  color: white;
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 5px;
  font-weight: 500;
  transition: background 0.3s;
}

.contact-button:hover {
  background: #5e35b1;
}

/* Billing Toggle */
.billing-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px;
  font-weight: 500;
}

.billing-option {
  margin: 0 10px;
  color: #666;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.billing-option.active {
  color: #558392;
  font-weight: bold;
}

.save-badge {
  background-color: #4f6f52;
  color: white;
  font-size: 0.7rem;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 8px;
}

/* Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 0 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #4f6f52;
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

/* Månedlig ekvivalent pris */
.price-comparison {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
}

.monthly-equivalent {
  font-style: italic;
}

/* Rabatt boble */
.discount-bubble {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff5722;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 6px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(255, 87, 34, 0.3);
  transform: rotate(-5deg);
  z-index: 2;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: rotate(-5deg) scale(1);
  }
  50% {
    transform: rotate(-5deg) scale(1.05);
  }
  100% {
    transform: rotate(-5deg) scale(1);
  }
}

.discount-bubble.bronze {
  background-color: #ff9800;
}

.discount-bubble.silver {
  background-color: #2196f3;
}

.discount-bubble.gold {
  background-color: #4caf50;
}

/* Fremhev spesielle funksjoner */
.highlighted-feature {
  color: #558392;
  font-weight: bold;
}

/* Enterprise plan styling */
.enterprise-plan {
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.8),
    rgba(240, 248, 255, 0.8)
  );
  border: 1px solid #558392;
}

/* Enterprise section improvements */
.enterprise-benefits {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  text-align: center;
  flex-wrap: wrap;
}

.benefit {
  flex: 1;
  min-width: 150px;
  padding: 15px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.benefit strong {
  color: #558392;
  font-size: 1.2rem;
  display: block;
  margin-bottom: 8px;
}

.benefit p {
  margin: 0;
  color: #666;
}

/* Toast button styling */
.toast-button {
  background-color: #558392;
  color: white;
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  display: block;
  width: 100%;
  transition: background-color 0.3s ease;
}

.toast-button:hover {
  background-color: #3f6673;
}

/* Toastify override for better styling */
.Toastify__toast-container {
  width: 360px !important;
}

.Toastify__toast {
  border-radius: 8px !important;
  padding: 16px !important;
}

.Toastify__toast-body {
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 768px) {
  .plans-grid {
    grid-template-columns: 1fr;
  }
  .enterprise-benefits {
    flex-direction: column;
  }
}
