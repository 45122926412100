:root {
  --primary-color: #558392;
  --primary-light: rgba(85, 131, 146, 0.1);
  --dark-color: #333;
  --text-color: #444;
  --light-text: #666;
  --border-color: #e0e0e0;
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --danger-color: #f44336;
  --background-light: #f9f9fb;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 20px rgba(0, 0, 0, 0.15);
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 12px;
}

/* Base layout styles - Improved positioning relative to navbar */
.mobile-gantt-projects {
  padding: 20px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - var(--navbar-height, 80px) - 40px
  ); /* Dynamic height calculation */
  width: 100%;
  max-width: 100vw;
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
  position: fixed;
  top: calc(
    var(--navbar-height, 80px) + 10px
  ); /* Position based on navbar height plus spacing */
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: pan-x pan-y;
  z-index: 5; /* Lower z-index than navbar */
}

/* Header styles - OPTIMIZED */
.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px; /* Added top margin */
  margin-bottom: 8px; /* Reduced from 16px to 8px */
  padding: 10px 16px;
  width: 100%;
  position: relative;
  z-index: 5;
}

/* Fix for the overly tall header */
.mobile-header.mobile-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px; /* Reduced from 12px to 8px */
  padding: 8px 12px;
  background-color: white;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  height: auto;
  min-height: 50px;
  box-sizing: border-box;
  gap: 10px;
  width: 100%;
}

.mobile-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--dark-color);
  margin: 0;
}

.mobile-actions {
  display: flex;
  align-items: center;
  gap: 10px; /* Increased from 8px to match GanttChart */
  justify-content: flex-start; /* Align left like GanttChart */
  flex-grow: 1;
  padding-left: 20px; /* Add padding like GanttChart */
}

/* Restore the original button styling */
.mobile-action-button {
  background-color: #f0f2f5; /* Match GanttChart button color */
  color: #65676b; /* Match GanttChart text color */
  border: none;
  border-radius: 20px; /* Change from 50% to 20px for rounded rectangle */
  width: auto; /* Auto width instead of fixed */
  height: 36px; /* Match GanttChart height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
  padding: 0 12px; /* Match GanttChart padding */
  box-shadow: none; /* Remove shadow to match GanttChart */
  font-size: 16px; /* Match GanttChart font size */
  min-width: 42px; /* Set minimum width */
}

.mobile-action-button:hover {
  background-color: #e4e6e9; /* Match GanttChart hover */
  color: #5c5d5f;
  transform: none; /* Remove transform on hover */
}

.mobile-action-button:active {
  transform: scale(0.95);
  background-color: #f0f0f0;
}

/* Style the "has-changes" state similar to btn-pending in GanttChart */
.mobile-action-button.has-changes {
  background-color: orange;
  color: white;
}

.mobile-action-button.has-changes:hover {
  background-color: #e69500;
  color: white;
}

.mobile-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: var(--danger-color);
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  box-shadow: var(--shadow-sm);
}

/* Restore the original button styling */
.mobile-add-button {
  background-color: #f0f2f5; /* Match other buttons */
  color: #65676b;
  border: none;
  border-radius: 20px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.2s ease;
  white-space: nowrap;
  height: 36px;
}

.mobile-add-button:hover {
  background-color: #e4e6e9;
  color: #5c5d5f;
  transform: none;
}

.mobile-add-button:active {
  transform: scale(0.98);
  background-color: #497a88;
}

/* Filter buttons - OPTIMIZED */
.mobile-filters {
  display: flex;
  gap: 6px;
  margin-bottom: 12px;
  padding-bottom: 6px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  padding-left: 4px;
  padding-right: 4px;
}

.mobile-filters::-webkit-scrollbar {
  display: none; /* Chrome/Safari */
}

.filter-button {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  padding: 6px 12px;
  font-size: 0.85rem;
  color: var(--light-text);
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  scroll-snap-align: start;
  flex-shrink: 0;
}

.filter-button.active {
  background-color: var(--primary-light);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/* Gantt chart container - FIXED FOR SCROLLING ISSUES */
.mobile-gantt-container {
  flex: 1;
  position: relative;
  border-radius: var(--radius-md);
  background-color: white;

  margin-bottom: 20px; /* Increase bottom margin */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Critical for flexbox scrolling */
  margin-top: 4px; /* Reduced from 8px to 4px */
}

.gantt-chart {
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
  touch-action: pan-x pan-y !important; /* Allow panning but prevent pinch zoom */
  -webkit-user-select: none; /* Prevent text selection which can trigger zoom */
  user-select: none;
  /* Hard-coded min-height to ensure the chart is visible */
  min-height: 300px;
  /* Change cursor to indicate the chart is interactive */
  cursor: grab;
}

/* Update cursor styles */
.gantt-chart {
  /* Keep existing styles */
  cursor: grab; /* Default grab cursor for chart area */
}

/* Task bars keep their pointer cursor */
.gantt .bar-wrapper {
  cursor: pointer !important; /* Ensure task bars show pointer cursor */
}

/* When actively grabbing the chart */
.gantt-chart.grabbing,
.gantt-chart.grabbing * {
  cursor: grabbing !important;
}

.gantt-chart:active {
  cursor: grabbing;
}

/* Fix for Gantt scrolling container to ensure proper scrolling */
.gantt .grid-container {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
  touch-action: pan-x pan-y !important;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}

/* Fix for Safari scroll */
.gantt-chart > svg,
.gantt > svg {
  height: auto;
  width: 100%;
}

/* Controls for zoom/today - FIXED POSITIONING */
.mobile-gantt-controls {
  position: fixed;
  bottom: 100px; /* Increase from 80px to match GanttChart's margin-bottom */
  right: 16px;
  display: flex;
  gap: 8px;
  z-index: 10;
  animation: slideInUp 0.5s ease-out;
}

/* Controls for zoom buttons - FIXED STYLING */
.gantt-control-btn {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-color);
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.gantt-control-btn:active {
  transform: scale(0.95);
  background-color: #f0f0f0;
}

/* Make the Frappe today button more visible */
.gantt .navigate-today {
  background-color: var(--primary-color) !important;
  color: white !important;
  padding: 6px 12px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
  border: none !important;
  margin: 8px !important;
}

.gantt .navigate-today:hover {
  background-color: #497a88 !important;
}

/* Modal styles - IMPROVED FOR SMALL SCREENS */
.mobile-modal-backdrop {
  position: fixed;
  top: 80px; /* Match the new top position of container */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 990; /* High but below navbar */
  display: flex;
  align-items: flex-start; /* Start from top instead of center */
  justify-content: center;
  animation: fadeIn 0.2s ease;
  padding: 24px 8px; /* Increase top padding */
  overflow-y: auto; /* Make it scrollable if needed */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mobile-task-modal {
  background-color: white;
  border-radius: var(--radius-lg);
  width: 100%;
  max-width: 480px;
  max-height: calc(100vh - 90px); /* Account for navbar + some padding */
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
  position: relative;
  animation: slideDown 0.25s ease;
  margin-top: 8px;
}

/* Change animation to slide from top instead of bottom */
@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Progress bar at top of modal */
.mobile-modal-progress-bar {
  height: 5px;
  background-color: #eee;
  border-radius: var(--radius-lg) var(--radius-lg) 0 0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

/* Modal header - OPTIMIZED */
.mobile-modal-header {
  padding: 14px;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}

.mobile-modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: var(--light-text);
  cursor: pointer;
  padding: 4px;
  display: flex;
  z-index: 5;
}

.mobile-modal-title {
  border: none;
  background: none;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dark-color);
  width: 100%;
  padding: 0;
  padding-right: 30px;
  margin-bottom: 8px;
}

.mobile-modal-title:focus {
  outline: none;
  border-bottom: 2px solid var(--primary-color);
}

.mobile-modal-status {
  margin-top: 6px;
}

.days-remaining {
  font-size: 0.85rem;
  color: var(--light-text);
  display: flex;
  align-items: center;
  gap: 6px;
}

.days-remaining.overdue {
  color: var(--danger-color);
}

/* Modal body - OPTIMIZED */
.mobile-modal-body {
  padding: 14px;
}

.mobile-form-group {
  margin-bottom: 14px;
}

.mobile-form-label {
  display: block;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--light-text);
  margin-bottom: 4px;
}

.mobile-form-input,
.mobile-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  font-size: 0.95rem;
  background-color: white;
  box-sizing: border-box;
}

.mobile-form-value {
  padding: 8px 0;
  font-size: 0.95rem;
  color: var(--text-color);
}

.mobile-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

/* Mobile orientation fixes */
@media screen and (max-height: 500px) and (orientation: landscape) {
  .mobile-gantt-container {
    min-height: 200px;
  }

  .mobile-header {
    margin-bottom: 8px;
  }

  .mobile-filters {
    margin-bottom: 8px;
  }

  .mobile-form-row {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .mobile-task-modal {
    max-height: calc(100vh - 80px);
  }

  .mobile-modal-backdrop {
    align-items: flex-start;
    padding-top: 8px;
  }
}

/* Modal actions - OPTIMIZED */
.mobile-modal-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.mobile-save-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  padding: 12px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mobile-save-button:active {
  background-color: #497a88;
}

.mobile-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: transparent;
  color: var (--danger-color);
  border: 1px solid var(--danger-color);
  border-radius: var(--radius-sm);
  padding: 10px;
  font-size: 0.85rem;
  cursor: pointer;
}

/* Delete confirmation - OPTIMIZED */
.mobile-delete-confirm {
  padding: 8px;
  text-align: center;
}

.mobile-delete-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.mobile-confirm-delete {
  flex: 1;
  background-color: var(--danger-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: var(--radius-sm);
  cursor: pointer;
}

.mobile-cancel-delete {
  flex: 1;
  background-color: #eee;
  border: none;
  padding: 10px;
  border-radius: var(--radius-sm);
  cursor: pointer;
}

/* Gantt chart tooltip customization - OPTIMIZED */
.mobile-popup-wrapper {
  padding: 8px;
  background-color: white;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  min-width: 180px;
  position: relative;
  overflow: hidden;
}

.mobile-popup-progress {
  height: 4px;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-popup-progress.high-progress {
  background-color: var(--success-color);
}

.mobile-popup-progress.medium-progress {
  background-color: #2196f3;
}

.mobile-popup-progress.low-progress {
  background-color: var(--warning-color);
}

.mobile-popup-progress.no-progress {
  background-color: #9e9e9e;
}

.mobile-popup-title {
  font-weight: 600;
  padding-top: 8px;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.mobile-popup-dates {
  display: flex;
  gap: 8px;
  color: var(--light-text);
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.mobile-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
  margin-top: 4px;
}

.mobile-popup-progress-text {
  font-size: 0.75rem;
  color: var(--light-text);
}

.mobile-popup-tap-hint {
  font-size: 0.75rem;
  color: var(--primary-color);
}

/* Loading and empty state - OPTIMIZED */
.mobile-loading,
.mobile-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 20px;
  text-align: center;
}

.mobile-loading-spinner {
  width: 36px;
  height: 36px;
  border: 3px solid rgba(85, 131, 146, 0.3);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.mobile-empty-icon {
  font-size: 3rem;
  margin-bottom: 14px;
}

.mobile-empty-state h3 {
  margin-bottom: 8px;
  color: var(--dark-color);
}

.mobile-empty-state p {
  margin-bottom: 20px;
  color: var(--light-text);
}

/* Frappe Gantt styling enhancements */
.gantt .bar-wrapper {
  cursor: pointer;
  touch-action: pan-x pan-y;
}

.gantt .bar-wrapper .bar {
  height: 36px; /* Taller bars for easier touch */
  rx: 5;
  ry: 5;
  position: relative; /* Ensure relative positioning for handle alignment */
}

/* Ensure handles are correctly positioned */
.gantt .bar-wrapper .handle {
  position: absolute;
  transform-origin: center;
}

/* Left duration handle */
.gantt .bar-wrapper .handle.left {
  left: 0;
  transform: translate(-50%, -50%) scale(1.2);
}

/* Right duration handle */
.gantt .bar-wrapper .handle.right {
  right: 0;
  transform: translate(50%, -50%) scale(1.2);
}

/* Progress handle */
.gantt .bar-wrapper .handle.progress {
  bottom: -6px; /* Position progress handle from bottom instead of top */
  top: auto;
  transform: translate(-50%, 50%) scale(1.2);
}

/* Increase the hit area for the handles on touch devices */
.gantt .bar-wrapper .handle circle {
  r: 5; /* Larger radius for better touch targets */
}

/* Fix for SVG positioning within the chart */
.gantt svg {
  overflow: visible; /* Allow elements to render outside their container */
  width: 100%;
}

.gantt .bar-wrapper .handle {
  transform: scale(1.2);
}

.gantt-container {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}

/* Prevent text selection during touch */
.gantt text {
  user-select: none;
  pointer-events: none;
}

/* Give better visibility to the current day */
.gantt .today-highlight {
  fill: rgba(85, 131, 146, 0.15);
}

/* Customizing scrollbars for webkit browsers */
.gantt .grid-container::-webkit-scrollbar,
.gantt-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.gantt .grid-container::-webkit-scrollbar-track,
.gantt-container::-webkit-scrollbar-track {
  background: transparent;
}

.gantt .grid-container::-webkit-scrollbar-thumb,
.gantt-container::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
  border: 2px solid transparent;
}

.gantt .grid-container::-webkit-scrollbar-thumb:hover,
.gantt-container::-webkit-scrollbar-thumb:hover {
  background-color: #cccccc;
}

/* Smaller screens specific fixes */
@media (max-width: 350px) {
  .mobile-title {
    font-size: 1.1rem;
  }

  .mobile-action-button {
    width: 32px;
    height: 32px;
  }

  .filter-button {
    padding: 5px 10px;
    font-size: 0.8rem;
  }

  .mobile-add-button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .mobile-form-row {
    grid-template-columns: 1fr;
  }
}

html,
body {
  touch-action: pan-x pan-y; /* Allow panning but prevent pinch zoom */
}

.mobile-gantt-view {
  touch-action: pan-x pan-y;
  overflow: hidden;
}

/* Ensure buttons maintain consistent height */
.mobile-action-button,
.mobile-add-button {
  height: 36px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add this to ensure consistent button sizing */
.mobile-action-button svg,
.mobile-add-button svg {
  flex-shrink: 0;
}

/* Add zoom onboarding styles */
.mobile-zoom-onboarding {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 24px 20px 40px; /* Increased bottom padding to accommodate the Try Me text */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
  max-width: 80%;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.zoom-onboarding-text {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
}

.zoom-onboarding-controls {
  display: flex;
  gap: 16px;
  margin-bottom: 10px; /* Added margin at the bottom for spacing */
  position: relative; /* Make this a positioning context */
}

.zoom-onboarding-btn {
  background-color: #f0f5ff;
  border: 2px solid #3b82f6;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  transform: scale(1);
  transition: transform 0.2s ease;
}

.zoom-onboarding-btn:active {
  transform: scale(0.95);
}

/* Reposition the Try Me text to be inside the modal */
.try-me-text {
  position: absolute;
  bottom: -32px; /* Position below the buttons but inside the modal */
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  font-weight: 600;
  color: #3b82f6;
  white-space: nowrap;
  padding: 4px 10px;
  background-color: rgba(59, 130, 246, 0.1); /* Light blue background */
  border-radius: 12px;
  border: 1px dashed #3b82f6;
}

/* Blinking animation */
.blink {
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add animation for the transition of controls to their final position */
@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
