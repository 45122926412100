/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Grid Layout for Company Page */
.company-container {
  display: grid;
  grid-template-columns: 0.8fr 2fr;
  gap: 24px;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .company-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    padding: 16px;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .company-header,
  .company-description {
    font-size: 0.8rem;
  }
}

/* Header Section */
.company-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.company-name {
  font-size: 2rem;
  font-weight: 700;
  color: #313131;
  font-family: 'Roboto', sans-serif;
}

.company-tagline {
  font-size: 1.2rem;
  font-weight: 400;
  color: #558392;
  font-style: italic;
}

/* Company Description */
.company-description {
  font-size: 1rem;
  color: #313131;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  margin-bottom: 20px;
}

.CreateCompanyForm {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  gap: 10px;
}

.CreateCompanyForm form label {
  display: block;
  margin-bottom: 1rem;
  color: #333;
}

.CreateCompanyForm input,
.CreateCompanyForm textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.CreateCompanyForm button {
  padding: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.CreateCompanyForm button:hover {
  background-color: #607d8b;
}

/* Sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  justify-content: start;
  gap: 2px;
  background: #ffffff;
  padding: 10px 10px 10px 10px;

  border-radius: 20px;
}

.sidebar .sidebar-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #313131;
}

.sidebar-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.sidebar-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar-item .sidebar-item-name {
  font-size: 1rem;
  font-weight: 500;
  color: #313131;
}

/* Button for adding new employees, projects, etc. */
.add-button {
  width: 100%;
  padding: 10px;
  background-color: #558392;
  color: white;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: orange;
}

/* Companies List Section */
.companies-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.company-card {
  background-color: white;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.company-card-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.company-card-header img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.company-card-header .company-card-name {
  font-weight: bold;
  font-size: 1.2rem;
  color: #313131;
}

.company-card-header .company-card-position {
  font-size: 0.9rem;
  color: #558392;
}

.company-card-description {
  font-size: 0.9rem;
  color: #313131;
}

.company-card-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.view-button,
.message-button {
  background-color: #558392;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.view-button:hover,
.message-button:hover {
  background-color: orange;
}

/* Search Section */
.search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-container input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}

.search-container button {
  background-color: #558392;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: orange;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  gap: 10px;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.modal-content button {
  padding: 10px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.modal-content button:hover {
  background-color: #607d8b;
}

body.modal-active {
  overflow: hidden;
}

/* Following / Followers Cards */
.search-card,
.following-card,
.followers-card {
  width: 100%; /* Make sure the cards fill the width of the container */
  max-width: 350px; /* Optional: to ensure a max width for the cards */
  height: auto; /* Set a consistent height for the cards */
  background: #fff; /* Background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px; /* Padding inside the card */
  display: flex; /* Flexbox for layout */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Gap between items */
}

.user-profile-image {
  width: 48px; /* Width of the profile image */
  height: 48px; /* Height of the profile image */
  border-radius: 8px; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the area */
}

.user-details {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  gap: 5px; /* Gap between items */
}

.add-user-btn,
.remove-user-btn {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 5px 10px; /* Padding inside the button */
  cursor: pointer; /* Pointer cursor on hover */
}

.add-user-btn:hover,
.remove-user-btn:hover {
  background-color: #0056b3; /* Darker background on hover */
}

/* ===== CompanyPage Layout & Structure ===== */
.main-content {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

/* ===== Typography & Headers ===== */
.page-header {
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.page-header h1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-header h1 svg {
  color: #558392;
}

.page-description {
  font-size: 1rem;
  color: #666;
  margin-top: 8px;
}

.admin-section h2 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #444;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-section h2 svg {
  color: #558392;
}

.section-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 20px 0 12px 0;
}

/* ===== Form Styling ===== */
.admin-form {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-width: 500px;
  margin-bottom: 24px;
}

.admin-form h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.4rem;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #555;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-control:focus {
  border-color: #558392;
  outline: none;
  box-shadow: 0 0 0 3px rgba(85, 131, 146, 0.2);
}

.error-message {
  color: #dc3545;
  margin-top: 6px;
  font-size: 0.9rem;
}

/* ===== License Management Section ===== */
.license-management {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-top: 24px;
  border: 1px solid #eee;
}

.license-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
}

.license-stats {
  display: flex;
  gap: 12px;
}

.license-stat-item {
  background-color: #f8f9fa;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
}

.license-stat-value {
  font-weight: 600;
  color: #558392;
}

.licensed-users-list {
  margin-top: 20px;
}

.licensed-users-list h3 {
  font-size: 1.1rem;
  margin-bottom: 12px;
  color: #444;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
}

.users-table th,
.users-table td {
  padding: 10px 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-table th {
  font-weight: 500;
  color: #666;
  background-color: #f8f9fa;
}

.users-table tr:last-child td {
  border-bottom: none;
}

/* ===== User Cards ===== */
.section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.user-card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eee;
  overflow: hidden;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card-body h5 {
  font-size: 1rem;
  margin: 0;
}

.card-body p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.user-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 8px;
}

.card-body label {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
}

/* ===== Buttons ===== */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  gap: 6px;
}

.btn-primary {
  background-color: #558392;
  color: white;
}

.btn-primary:hover {
  background-color: #466d7a;
}

.btn-secondary {
  background-color: #f0f2f5;
  color: #333;
}

.btn-secondary:hover {
  background-color: #e4e6e9;
}

.btn-danger {
  background-color: #fff;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.btn-danger:hover {
  background-color: #dc3545;
  color: white;
}

.btn-success {
  background-color: #558392;
  color: white;
}

.btn-success:hover {
  background-color: orange;
}

.add-user-btn {
  background-color: #558392;
  color: white;
}

.add-user-btn:hover {
  background-color: #466d7a;
}

.remove-user-btn {
  background-color: #f8d7da;
  color: #721c24;
}

.remove-user-btn:hover {
  background-color: #f5c6cb;
}

/* ===== Modal Styling ===== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.4rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.form-group input:focus {
  border-color: #558392;
  outline: none;
  box-shadow: 0 0 0 3px rgba(85, 131, 146, 0.2);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

/* ===== Media Queries ===== */
@media (max-width: 768px) {
  .company-container {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  .license-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .users-table {
    display: block;
    overflow-x: auto;
  }

  .section-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 16px;
  }

  .license-stats {
    flex-direction: column;
    gap: 8px;
  }

  .section-grid {
    grid-template-columns: 1fr;
  }
}

/* Adding styles for the member list that were missing */

/* Company members list */
.company-members-list {
  margin-top: 30px;
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.company-members-list h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 20px 0;
  font-size: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #558392;
  padding-bottom: 10px;
}

.company-members-list h3 svg {
  color: #558392;
}

.company-members-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.company-members-list li {
  padding: 16px 12px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.3s ease;
  animation: company-slide-right 0.5s forwards;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-members-list li:nth-child(2) {
  animation-delay: 0.1s;
}
.company-members-list li:nth-child(3) {
  animation-delay: 0.2s;
}
.company-members-list li:nth-child(4) {
  animation-delay: 0.3s;
}
.company-members-list li:nth-child(5) {
  animation-delay: 0.4s;
}

.company-member-info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.company-member-name {
  font-weight: 600;
  color: #333;
  min-width: 150px;
}

.company-member-email {
  color: #666;
  flex: 1;
}

.company-member-role {
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.company-member-role.admin {
  background-color: #558392;
  color: white;
}

.company-members-list li:last-child {
  border-bottom: none;
}

.company-members-list li:hover {
  background-color: #f0f7fa;
}

/* Save button adjustments */
.company-save-btn {
  width: auto;
  margin: 20px 0 10px 0;
  padding: 10px 20px;
}

/* Fix selected users layout */
.company-selected-users .company-card-body {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.company-admin-checkbox {
  display: flex;
  align-items: center;
}

.company-admin-checkbox label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  user-select: none;
}

/* Error message styling */
.company-error-message {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 14px;
}

/* Search results container */
.company-search-container {
  margin-bottom: 30px;
}

/* Empty state messages */
.company-admin-section p {
  color: #666;
  font-style: italic;
  padding: 10px 0;
}

/* Lisensstiler (legg disse til i slutten av din eksisterende CSS-fil) */

.license-management-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.license-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.license-stats {
  display: flex;
  gap: 20px;
}

.license-stats span {
  background-color: #f0f5ff;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #558392;
}

.licensed-users-list {
  margin-top: 20px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.users-table th,
.users-table td {
  border: 1px solid #eee;
  padding: 10px;
  text-align: left;
}

.users-table th {
  background-color: #f8f9fa;
}

.remove-button {
  background-color: #f8d7da;
  border: none;
  color: #721c24;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #f5c6cb;
}

.add-button {
  background-color: #0275d8;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.add-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancel-button {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
