.task-assignee-container {
  position: relative;
  width: 100%;
}

.assigned-user {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.assigned-user:hover {
  background-color: rgba(85, 131, 146, 0.1);
}

.assignee-avatar {
  width: 28px; /* Updated from 20px */
  height: 28px; /* Updated from 20px */
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #e5e7eb;
}

/* Simple search container */
.search-container {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 240px;
  background: white;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .search-container {
    right: 0;
    left: auto;
    width: 220px;
  }
}

.search-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

/* Simple X button */
.close-search-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Clean search input */
.search-input {
  flex-grow: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 14px;
  outline: none;
}

.search-input:focus {
  border-color: #558392;
}

/* Simple results list */
.search-results {
  max-height: 200px;
  overflow-y: auto;
}

/* Clean result items */
.search-result-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: #f5f7fa;
}

/* Profile image in results */
.result-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

/* User details in results */
.result-details {
  display: flex;
  flex-direction: column;
}

.result-name {
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}

.result-company {
  font-size: 0.7rem;
  color: #666;
}

.task-assignee-wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  isolation: isolate; /* Create a stacking context */
}

/* Current assignee display */
.current-assignee {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px; /* Updated padding */
  background-color: rgba(79, 111, 82, 0.08);
  border: 1px solid rgba(79, 111, 82, 0.1);
  border-radius: 6px; /* Updated border-radius */
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.2s;
}

.current-assignee:hover {
  background-color: rgba(
    79,
    111,
    82,
    0.12
  ); /* Updated hover background-color */
}

.assignee-info {
  display: flex;
  flex-direction: column;
  gap: 1px; /* Reduced from 2px */
  text-align: left; /* Ensure text is left-aligned */
  font-size: 0.8rem;
}

.task-assignee-compact {
  margin-left: 0; /* Remove auto margin */
  margin-right: auto; /* Push everything else to the right */
  min-width: 150px; /* Adjust width as needed */
}

.assignee-name {
  font-weight: 500;
  color: #333;
  font-size: 0.75rem; /* Reduced font size */
  line-height: 1.2; /* Updated line height */
}

.assignee-company {
  font-size: 0.65rem; /* Even smaller company text */
  color: #666;
  line-height: 1.2; /* Updated line height */
}

.current-assignee-display {
  padding: 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
}

.current-assignee-display:hover {
  background: #fff;
  border-color: #558392;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
  .current-assignee {
    padding: 4px 6px; /* Updated padding */
  }

  .assignee-avatar {
    width: 24px;
    height: 24px;
  }

  .assignee-name {
    font-size: 0.75rem;
  }

  .assignee-company {
    font-size: 0.65rem;
  }
}

/* Rename this specific search container to avoid conflicts with MobileProjects.css */
.task-assignee-search-container {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 240px;
  background: white;
  z-index: 2000; /* Higher z-index to ensure it's on top */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
}

/* Update your other search-related styles */
.task-assignee-search-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.task-assignee-search-input {
  flex-grow: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 14px;
  outline: none;
}

.task-assignee-search-input:focus {
  border-color: #558392;
}

.task-assignee-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-assignee-search-results {
  max-height: 200px;
  overflow-y: auto;
}

.task-assignee-result-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.task-assignee-result-item:last-child {
  border-bottom: none;
}

.task-assignee-result-item:hover {
  background-color: #f5f7fa;
}

/* Base wrapper styles */
.task-assignee-wrapper {
  position: relative;
  z-index: 100;
  width: 100%;
  isolation: isolate; /* Create a stacking context */
}

/* Table-specific (GanttChart) styles */
.gantt-table-context {
  height: 46px !important; /* Fixed height to match table row height */
  min-height: 46px !important;
  max-height: 46px !important;
  display: flex;
  align-items: center;
}

.gantt-table-context .current-assignee {
  padding: 2px 4px;
  gap: 6px;
  height: 36px !important; /* Updated height */
  max-height: 36px !important; /* Updated max-height */
}

.gantt-table-context .assignee-avatar {
  width: 24px; /* Updated width */
  height: 24px; /* Updated height */
}

.gantt-table-context .assignee-name {
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.gantt-table-context .assignee-company {
  font-size: 0.6rem;
  line-height: 1;
}

/* Timeline-specific (MobileProjects) styles */
.timeline-context .current-assignee {
  padding: 8px; /* Updated padding */
}

.timeline-context .assignee-avatar {
  width: 32px; /* Updated width */
  height: 32px; /* Updated height */
}

/* Common current assignee styles */
.current-assignee {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.5rem;
  background-color: rgba(79, 111, 82, 0.08);
  border: 1px solid rgba(79, 111, 82, 0.1);
  border-radius: 8px;
  min-height: auto;
  justify-content: flex-start;
  width: fit-content;
}

.current-assignee-display {
  padding: 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
  width: 100%;
}

.current-assignee-display:hover {
  background: rgba(255, 255, 255, 0.7);
}

.assignee-info {
  display: flex;
  flex-direction: column;
  gap: 1px;
  text-align: left;
  font-size: 0.8rem;
}

.assignee-name {
  font-weight: 500;
  color: #333;
  font-size: 0.75rem;
  line-height: 1;
}

.assignee-company {
  font-size: 0.65rem;
  color: #666;
  line-height: 1;
}

/* Search container with improved positioning */
.task-assignee-search-container {
  position: absolute;
  z-index: 2000;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
}

/* Different positioning for different contexts */
.gantt-table-context .task-assignee-search-container {
  width: 220px;
  top: 46px; /* Position below the table row */
  left: 0;
}

.timeline-context .task-assignee-search-container {
  width: 240px;
  top: calc(100% + 2px);
  left: 0;
}

/* Search header and controls */
.task-assignee-search-header {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.task-assignee-search-input {
  flex-grow: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 14px;
  outline: none;
}

.task-assignee-search-input:focus {
  border-color: #558392;
}

.task-assignee-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Search results list */
.task-assignee-search-results {
  max-height: 200px;
  overflow-y: auto;
}

.task-assignee-result-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.task-assignee-result-item:last-child {
  border-bottom: none;
}

.task-assignee-result-item:hover {
  background-color: #f5f7fa;
}

/* Result item styling */
.result-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.result-details {
  display: flex;
  flex-direction: column;
}

.result-name {
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}

.result-company {
  font-size: 0.7rem;
  color: #666;
}

/* Mobile adjustments */
@media (max-width: 480px) {
  .gantt-table-context .task-assignee-search-container {
    width: 200px;
    right: 0;
    left: auto;
  }
}

/* Table cell specific fixes */
.assignee-cell {
  position: relative !important; /* Creates positioning context */
  overflow: visible !important; /* Ensures dropdown is not cut off */
  min-width: 150px; /* Ensures minimum width for dropdown */
  z-index: 5; /* Establishes stacking context */
}

/* Adjust search container positioning for table context */
.gantt-table-context .task-assignee-search-container {
  position: absolute;
  top: 100% !important; /* Position right below the cell */
  left: 0;
  width: 240px;
  z-index: 3000 !important; /* Much higher z-index to overcome table layers */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Make sure table rows don't clip overflow */
.table-container table tr {
  overflow: visible !important;
}

/* Make sure the search container is visible when in table */
.task-assignee-wrapper.gantt-table-context {
  position: static !important; /* Important for proper positioning in tables */
  min-height: unset !important;
  max-height: unset !important;
}

/* Fix positioning when inside a table cell */
td .task-assignee-search-container {
  position: absolute;
  left: 0;
  margin-top: 2px;
}

/* Fix z-index for elements in table cell context */
.gantt-table-context .task-assignee-search-header,
.gantt-table-context .task-assignee-search-results {
  z-index: 3000 !important;
}

/* Ensure the dropdown doesn't inherit table cell constraints */
.gantt-table-context .task-assignee-search-container {
  max-height: none;
  overflow: visible;
}

/* Add a small visual indicator that the cell is clickable */
.assignee-cell:hover .current-assignee {
  border-color: #558392;
}

/* Fix the table cell z-index issues */
td.assignee-cell {
  position: relative !important;
  overflow: visible !important;
  z-index: 10; /* Base z-index */
  height: 46px !important;
  min-height: 46px !important;
  max-height: 46px !important;
}

/* When dropdown is open, increase z-index dramatically */
td.assignee-cell.active {
  z-index: 9999 !important;
}

/* Position the dropdown properly for gantt-table context */
.gantt-table-context .task-assignee-search-container {
  position: fixed !important; /* Use fixed instead of absolute */
  top: auto !important; /* Don't use top calculation */
  left: auto !important; /* Don't use left calculation */
  transform: translateY(3px); /* Small offset from the bottom of the cell */
  width: 240px !important;
  z-index: 9999 !important;
}

/* Complete overhaul of the dropdown positioning for table context */
.gantt-table-context .task-assignee-search-container {
  position: fixed !important; /* Use fixed positioning relative to viewport */
  z-index: 9999 !important; /* Extremely high z-index to overcome all layers */
  width: 240px !important;
  margin-top: 2px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2) !important; /* Stronger shadow */
  border: 1px solid #ccc;
}

/* Make sure the assignee cell doesn't have overflow constraints */
td.assignee-cell {
  position: relative !important;
  overflow: visible !important;
  z-index: auto !important;
}

/* Ensure the table doesn't clip overflow */
.table-container table {
  overflow: visible !important;
}

/* Ensure the table cell doesn't have any overflow:hidden */
.table-container td {
  overflow: visible !important;
}

/* Make position:fixed work correctly by removing transform properties */
.gantt-container-wrapper,
.table-container,
.main-content {
  transform: none !important;
}

/* Dropdown container */
.assignee-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  z-index: 1000;
  overflow: hidden;
}

/* Search header */
.assignee-search-header {
  display: flex;
  padding: 8px;
  background: #f9f9fa;
  border-bottom: 1px solid #eee;
  align-items: center;
}

/* Search input */
.assignee-search-input {
  flex: 1;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.assignee-search-input:focus {
  border-color: #558392;
}

/* Close button */
.assignee-close-btn {
  background: none;
  border: none;
  margin-left: 8px;
  padding: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.assignee-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Results container */
.assignee-search-results {
  max-height: 220px;
  overflow-y: auto;
}

/* Result items */
.assignee-result-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #f0f0f0;
}

.assignee-result-item:last-child {
  border-bottom: none;
}

.assignee-result-item:hover {
  background-color: #f5f7fa;
}

/* No results message */
.no-results {
  padding: 12px;
  text-align: center;
  color: #666;
  font-size: 0.85rem;
}

/* Context-specific styling */
.gantt-table-context .assignee-dropdown {
  width: 220px;
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .assignee-dropdown {
    width: 220px;
  }
}

/* Base container - cleaned up version */
.assignee-container {
  position: relative;
  z-index: 1; /* Base z-index */
}

/* Modal backdrop - covers entire screen */
.assignee-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Very high z-index to be above everything */
  animation: fadeIn 0.15s ease-in-out;
}

/* Modal container */
.assignee-modal {
  background: white;
  border-radius: 8px;
  width: 300px;
  max-width: 90vw;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.15s ease-in-out;
  overflow: hidden;
}

/* Modal header */
.assignee-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  background-color: #f9f9fa;
}

.assignee-modal-header h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Close button in modal */
.modal-close-btn {
  background: none;
  border: none;
  color: #666;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Search section */
.assignee-modal-search {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
}

/* Search input */
.modal-search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.modal-search-input:focus {
  border-color: #558392;
  box-shadow: 0 0 0 2px rgba(85, 131, 146, 0.1);
}

/* Results section */
.assignee-modal-results {
  flex: 1;
  overflow-y: auto;
  max-height: 50vh;
  padding: 8px 0;
}

/* Result items */
.modal-result-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-result-item:hover {
  background-color: #f5f7fa;
}

/* Result avatar */
.result-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

/* Result text */
.result-details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.result-name {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

.result-company {
  font-size: 0.8rem;
  color: #666;
}

/* No results message */
.no-results {
  padding: 16px;
  text-align: center;
  color: #666;
}

/* Table context specific styles */
.gantt-table-context .current-assignee {
  padding: 4px 6px;
  height: 36px;
}

.gantt-table-context .assignee-avatar {
  width: 24px;
  height: 24px;
}

/* Timeline context specific styles */
.timeline-context .current-assignee {
  padding: 8px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Mobile adjustments */
@media (max-width: 480px) {
  .assignee-modal {
    width: 280px;
  }

  .current-assignee {
    padding: 4px 6px;
  }

  .assignee-avatar {
    width: 24px;
    height: 24px;
  }
}
