.payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  padding: 20px;
}

.payment-success-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.payment-error {
  border-left: 4px solid #f44336;
}

.payment-success-card h2 {
  color: #2e7d32;
  margin-bottom: 20px;
}

.payment-error h2 {
  color: #d32f2f;
}

.payment-success-card p {
  margin-bottom: 30px;
  color: #666;
  line-height: 1.6;
}

.payment-success-card button {
  background-color: #2e7d32;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-success-card button:hover {
  background-color: #1b5e20;
}

.payment-error button {
  background-color: #d32f2f;
}

.payment-error button:hover {
  background-color: #b71c1c;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
