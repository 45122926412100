.subscription-card {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  margin-bottom: 2rem;
  max-width: 1200px;
  margin: 30px auto;
  overflow: hidden;
}

.subscription-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.subscription-card .card-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #558392;
  border-bottom: 1px solid #f0f4f8;
  padding-bottom: 0.75rem;
  text-align: center;
}

.subscription-detail {
  margin-bottom: 1rem;
}

.subscription-detail h5 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  color: #4f6f52;
}

.subscription-detail .value {
  font-size: 1.2rem;
  font-weight: 500;
  color: #558392;
  margin-bottom: 0;
}

.subscription-detail .badge {
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Custom button styling to match SubscriptionPlans */
.subscription-card .btn {
  font-weight: 500;
  padding: 12px;
  border-radius: 5px;
  transition: all 0.3s;
  width: 100%;
  margin-bottom: 10px;
}

/* Primary button styling */
.subscription-card .btn-primary,
.subscription-card .btn-outline-primary {
  color: white;
  background-color: #558392;
  border-color: #558392;
  box-shadow: 0px 0px 10px rgba(85, 131, 146, 0.3);
}

.subscription-card .btn-primary:hover,
.subscription-card .btn-outline-primary:hover {
  background-color: #476d7a;
  border-color: #476d7a;
  box-shadow: 0px 0px 15px rgba(85, 131, 146, 0.5);
}

/* Danger button styling */
.subscription-card .btn-outline-danger {
  color: #ff5722;
  background-color: transparent;
  border-color: #ff5722;
  border-width: 2px;
  box-shadow: 0px 0px 10px rgba(255, 87, 34, 0.1);
}

.subscription-card .btn-outline-danger:hover {
  background-color: rgba(255, 87, 34, 0.1);
  color: #ff5722;
  box-shadow: 0px 0px 15px rgba(255, 87, 34, 0.2);
}

/* Success button styling */
.subscription-card .btn-outline-success {
  color: #4f6f52;
  background-color: transparent;
  border-color: #4f6f52;
  border-width: 2px;
  box-shadow: 0px 0px 10px rgba(79, 111, 82, 0.1);
}

.subscription-card .btn-outline-success:hover {
  background-color: rgba(79, 111, 82, 0.1);
  color: #4f6f52;
  box-shadow: 0px 0px 15px rgba(79, 111, 82, 0.2);
}

/* Secondary button styling */
.subscription-card .btn-outline-secondary {
  color: #558392;
  background-color: transparent;
  border-color: #558392;
  border-width: 2px;
  box-shadow: 0px 0px 10px rgba(85, 131, 146, 0.1);
}

.subscription-card .btn-outline-secondary:hover {
  background-color: rgba(85, 131, 146, 0.1);
  color: #558392;
  box-shadow: 0px 0px 15px rgba(85, 131, 146, 0.2);
}

/* Background color for the page */
.subscription-manager-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 40px 20px;
  background: #f0f4f8;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

/* Badge styling to match SubscriptionPlans */
.subscription-card .badge-success {
  background-color: #4f6f52;
}

.subscription-card .badge-danger {
  background-color: #ff5722;
}

.subscription-card .badge-warning {
  background-color: #ff9800;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .subscription-card .d-flex {
    flex-direction: column;
    align-items: stretch;
  }

  .subscription-card .btn {
    margin-bottom: 0.75rem;
    width: 100%;
  }

  .subscription-card .btn:last-child {
    margin-bottom: 0;
  }

  .subscription-detail {
    text-align: center;
    padding: 0.5rem;
    border-bottom: 1px solid #f0f4f8;
  }

  .subscription-detail:last-child {
    border-bottom: none;
  }
}

/* Animation for loading state */
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.subscription-card .spinner-border {
  animation: pulse 2s infinite;
  color: #558392;
}

/* Loading state styling */
.text-center.p-5 {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 30px auto;
  color: #558392;
}

/* Dark mode support if your app has it */
@media (prefers-color-scheme: dark) {
  .subscription-card {
    background-color: #212529;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  .subscription-card .card-title {
    color: #f8f9fa;
    border-bottom-color: #343a40;
  }

  .subscription-detail h5 {
    color: #adb5bd;
  }

  .subscription-detail .value {
    color: #e9ecef;
  }

  .subscription-card .btn-outline-primary:hover {
    background-color: rgba(13, 110, 253, 0.15);
  }

  .subscription-card .btn-outline-danger:hover {
    background-color: rgba(220, 53, 69, 0.15);
  }

  .subscription-card .btn-outline-success:hover {
    background-color: rgba(25, 135, 84, 0.15);
  }

  .subscription-card .btn-outline-secondary:hover {
    background-color: rgba(108, 117, 125, 0.15);
  }
}

/* Oppdatert Lemon Squeezy Portal Info Box uten venstre skygge */
.subscription-portal-info {
  background-color: rgba(85, 131, 146, 0.1);
  /* Fjernet border-left egenskapen som skapte linjen/skyggen */
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.subscription-portal-info .alert-heading {
  color: #558392;
  font-size: 1.4rem;
  margin-bottom: 15px;
  text-align: center; /* Legger til midtstilling */
}

.customer-portal-button {
  background-color: #558392;
  border-color: #558392;
  padding: 12px 25px;
  font-size: 1.1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 15px rgba(85, 131, 146, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-portal-button:hover {
  background-color: #476d7a;
  border-color: #476d7a;
  transform: translateY(-2px);
  box-shadow: 0px 6px 20px rgba(85, 131, 146, 0.35);
}

.customer-portal-button:active {
  transform: translateY(0);
}

/* Ikon stil */
.customer-portal-button svg {
  transition: transform 0.3s ease;
}

.customer-portal-button:hover svg {
  transform: translateX(3px);
}
