/* Base styles and common variables */
:root {
  --primary-color: #558392; /* Blue-teal as primary brand color */
  --primary-light: rgba(85, 131, 146, 0.1);
  --primary-medium: rgba(85, 131, 146, 0.4);
  --accent-color: #ff9800; /* Orange as accent color */
  --accent-hover: #ffb74d;
  --text-dark: #2c3e50;
  --text-medium: #546e7a;
  --text-light: #ffffff;
  --bg-light: #ffffff;
  --bg-medium: #f5f7f9;
  --shadow-soft: 0 5px 15px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --transition-fast: 0.2s ease;
  --transition-medium: 0.3s ease;
  --transition-slow: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --font-family: 'Roboto', sans-serif;
}

body {
  background: var(--bg-light);
  color: var(--text-dark);
  font-family: var(--font-family);
  line-height: 1.6;
}

/* General container styling */
.wrapper {
  padding: 0;
  min-height: 100vh;
  overflow-y: auto;
  position: relative;
  background-color: var(--bg-light);
}

/* Section titles - consistent styling for all headings */
.section-title {
  color: var(--text-dark);
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--accent-color);
  border-radius: 4px;
}

/* Common button styling */
.btn {
  background-color: var(--accent-color);
  color: var(--text-light);
  border: none;
  padding: 12px 24px;
  border-radius: var(--border-radius-sm);
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition-fast);
  text-align: center;
  display: inline-block;
}

.btn:hover {
  background-color: var(--accent-hover);
  transform: translateY(-2px);
  box-shadow: var(--shadow-soft);
}

/* Rain effect styling */
.rain-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  pointer-events: none; /* Gjør at vi kan klikke gjennom regnet */
}

.rain {
  position: absolute;
  background-color: var(--primary-medium);
  width: 15px;
  height: 15px; /* Samme som bredden for perfekt sirkel */
  border-radius: 50%; /* 50% gir perfekt sirkel når bredde og høyde er like */
  opacity: 0;
  transform: scale(0); /* Fjerner rotasjonen siden sirkler ikke trenger det */
  animation: falling 3s ease-in infinite;
}

/* Varierende størrelser for regndråpene, men fortsatt runde */
.rain:nth-child(3n) {
  width: 5px;
  height: 5px;
}
.rain:nth-child(3n + 1) {
  width: 8px;
  height: 8px;
}
.rain:nth-child(3n + 2) {
  width: 12px;
  height: 12px;
}

/* Oppdatert animasjon for runde dråper - fjernet rotasjonen */
@keyframes falling {
  0% {
    top: -20px;
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 0.7;
    transform: scale(1);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    top: 120%;
    opacity: 0;
    transform: scale(0.5);
  }
}

/* Landing container styling */
.landing-container {
  padding: 5rem 4rem;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(6, 1fr);
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.landing-logo {
  grid-column: 1 / 3;
  grid-row: 1;
  height: auto;
  align-items: center;
  display: flex;
}

.landing-mainmessage {
  width: 100%;
  height: 100%;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: var(--font-family);
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  opacity: 0;
  animation: fadeIn 0.6s ease forwards;
  font-family: var(--font-family);
  color: var(--text-dark);
  font-size: 1.1rem;
}

.list-item:nth-child(1) {
  animation-delay: 0.1s;
}
.list-item:nth-child(2) {
  animation-delay: 0.3s;
}
.list-item:nth-child(3) {
  animation-delay: 0.5s;
}
.list-item:nth-child(4) {
  animation-delay: 0.7s;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px; /* Space between icon and text */
  flex-shrink: 0; /* Prevent icon from shrinking */
  color: var(--primary-color);
}

/* Forhindre layout-skift for bilder */
.landing-image {
  grid-column: 3 / 7;
  grid-row: 1 / 3;
  display: flex;
  justify-content: center; /* Riktig sentrering horisontalt */
  align-items: center; /* Align to top instead of center */
  position: relative;
  opacity: 0;
  animation: fadeIn 0.8s ease forwards;
  animation-delay: 0.3s;
  overflow: hidden;
  /* Fjern skygge som kan gi inntrykk av ramme */
  box-shadow: none;
}

.landing-image img {
  max-width: 90%; /* Redusert fra 100% til 80% */
  max-height: 90%; /* Increased from 80% */
  width: auto; /* La høyden justeres automatisk for å bevare proporsjoner */
  height: auto;
  border-radius: var(
    --border-radius-lg
  ); /* Avrundede hjørner som matcher navbar */
  /*border: 1px solid rgba(255, 255, 255, 0.3); /* Subtil border som i navbar */
  /* box-shadow: 0px 4px 30px rgba(85, 131, 146, 0.2); /* Samme skygge som navbar */
  object-fit: contain;
  transition: var(
    --transition-medium
  ); /* Myk overgang ved størrelsesendringer */
  margin-top: 0;
}

/* Fjern ::before elementet som legger til en subtil skygge/ramme */
.landing-image::before {
  display: none;
}

.landing-midtext {
  background-color: var(--primary-color);
  padding: 4rem 2rem;
  color: var(--text-light);
}

.midtext-heading {
  color: var(--accent-color);
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: var(--font-family);
}

.midtext-paragraph {
  font-size: 1.1rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.7;
}

.midtext-sponsors-cta {
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-sm);
  font-weight: 500;
  max-width: 800px;
  border-left: 4px solid var(--accent-color);
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.6;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1100px) {
  .landing-container {
    padding: 3rem 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto; /* Presis justering av antall rader */
    gap: 2rem;
  }

  .landing-logo {
    grid-column: 1;
    grid-row: 1;
    justify-content: center;
  }

  /* Swap these two elements - Image now comes BEFORE bullet points */
  .landing-image {
    grid-column: 1;
    grid-row: 2; /* Was row 3, now row 2 */
    height: auto;
  }

  .landing-mainmessage {
    grid-column: 1;
    grid-row: 3; /* Was row 2, now row 3 */
    justify-content: center;
    padding: 0;
  }

  .section-title,
  .carousel-title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Newsletter Form Styles - Single Column Layout */
.newsletter-form-container {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-sm);
  padding: 2rem;
  margin: 2rem auto;
  max-width: 700px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
  min-height: 300px; /* Juster basert på faktisk høyde */
}

.newsletter-title {
  color: var(--text-light);
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Remove grid layouts and make everything stack */
.form-row-1,
.form-row-2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form-group {
  width: 100%;
}

.newsletter-form input,
.newsletter-form textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.15);
  color: var(--text-light);
  font-size: 1rem;
  transition: var(--transition-fast);
  font-family: var(--font-family);
}

.newsletter-form textarea {
  min-height: 120px;
  resize: vertical;
}

.newsletter-form input::placeholder,
.newsletter-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.newsletter-form input:focus,
.newsletter-form textarea:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.3);
}

.newsletter-submit-btn {
  background-color: var(--accent-color);
  color: var(--text-dark);
  font-weight: bold;
  border: none;
  padding: 1rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  transition: var(--transition-fast);
  margin-top: 0.5rem;
}

.newsletter-submit-btn:hover {
  background-color: var(--accent-hover);
  transform: translateY(-2px);
}

.newsletter-submit-btn:active {
  transform: translateY(1px);
}

.newsletter-submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.newsletter-success {
  background-color: rgba(76, 175, 80, 0.2);
  border-left: 4px solid #4caf50;
  padding: 1.5rem;
  color: var(--text-light);
  border-radius: var(--border-radius-sm);
  text-align: center;
  min-height: 120px; /* Match høyden på skjemaet det erstatter */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.newsletter-error {
  background-color: rgba(244, 67, 54, 0.2);
  border-left: 4px solid #f44336;
  padding: 0.8rem;
  color: var(--text-light);
  border-radius: var(--border-radius-sm);
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .newsletter-form-container {
    padding: 1.5rem;
    margin: 1.5rem 1rem;
  }
}

.form-group input,
.form-group textarea {
  box-sizing: border-box; /* Viktig for å forhindre skift med padding/border */
}

/* Skeleton styling */
.skeleton-wrapper {
  min-height: 100vh;
}

.skeleton-box {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-line {
  height: 24px;
  margin-bottom: 16px;
  width: 80%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Feature Showcase Styling */
.feature-showcase-container {
  padding: 6rem 2rem;
  background-color: rgba(96, 125, 139, 0.05);
  position: relative;
  overflow: visible;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.showcase-title {
  color: var(--text-dark);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 4rem;
  font-family: var(--font-family);
  position: relative;
}

.showcase-title:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

.showcase-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.showcase-item {
  flex: 0 1 500px;
  max-width: 500px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  animation: popIn 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 0.2s;
  transform: translateY(20px) scale(0.95);
  position: relative;
}

.showcase-item:nth-child(2) {
  animation-delay: 0.4s;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.showcase-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow:
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: perspective(1000px) rotateX(0deg);
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.showcase-image-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  z-index: 2;
  pointer-events: none;
}

.showcase-image-wrapper:hover {
  transform: translateY(-10px) perspective(1000px) rotateX(2deg);
  box-shadow:
    0 25px 45px rgba(0, 0, 0, 0.25),
    0 10px 20px rgba(0, 0, 0, 0.15);
}

.showcase-item:hover {
  z-index: 20;
}

.showcase-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease;
  position: relative;
  z-index: 1;
}

.showcase-image-wrapper:hover .showcase-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.4) 50%,
    transparent
  );
  padding: 30px 20px 20px;
  color: white;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
  z-index: 3;
}

.showcase-image-wrapper:hover .image-overlay {
  opacity: 1;
  transform: translateY(0);
}

.showcase-description {
  color: var(--text-dark);
  margin-top: 1.5rem;
  font-family: var(--font-family);
  line-height: 1.6;
  text-align: center;
  padding: 0 15px;
  font-size: 1.05rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transform: translateY(-5px);
  position: relative;
  z-index: 5;
}

/* Responsive adjustments for showcase */
@media (max-width: 768px) {
  .feature-showcase-container {
    padding: 3rem 1rem 4rem;
  }

  .showcase-item {
    margin-bottom: 3rem;
  }

  .showcase-title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .showcase-content {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .showcase-image-wrapper:hover {
    transform: translateY(-5px) perspective(1000px) rotateX(1deg);
  }
}

/* Updated Feature Carousel Styling */
.feature-carousel-container {
  padding: 5rem 2rem;
  background-color: transparent; /* Transparent container */
  position: relative;
  margin: 3rem 0;
  width: 100%;
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
  overflow: visible !important;
}

.carousel-title {
  color: var(--text-dark);
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 3rem;
  font-family: var(--font-family);
  position: relative;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--text-dark);
  font-size: 2rem;
  position: relative;
}

.carousel-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--accent-color);
  border-radius: 4px;
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

/* Fjern overflow: hidden fra carousel-wrapper for å vise knappene fullstendig */
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 500px;
  overflow: visible !important;
  width: 100%;
  border-radius: var(--border-radius-md);
  background-color: transparent;
  border: none;
  box-shadow: none;
}

/* Legg til dette for å skjule synlige slides som kommer utenfor */
.carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important; /* Dette skjuler neste slide */
  border-radius: var(--border-radius-md);
}

/* Lag en helt lik bakgrunn for både bilde og tekst */

/* 1. Først oppdaterer vi carousel-slide for å sikre en konsistent bakgrunn */
.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.5s ease;
  background-color: var(--bg-light); /* Fast lys bakgrunn */
  border-radius: var(
    --border-radius-md
  ); /* Avrundede hjørner for hele slider */
}

/* 2. Deretter gjør vi både bildeområdet og tekstområdet helt transparente */
.carousel-image-wrapper {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
  background-color: #ffffff !important; /* Helt transparent */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  border: solid rgba(255, 255, 255, 0.3);
  border-color: #e0e0e0;
}

.carousel-text {
  flex: 0 0 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent; /* Helt transparent */
}

/* 3. Oppdater eventuelt container-bakgrunnen for å sikre konsistens */
.feature-carousel-container {
  padding: 5rem 2rem;
  background-color: transparent; /* Transparent container */
  position: relative;
  margin: 3rem 0;
  width: 100%;
  max-width: 1200px;
  margin: 60px auto;
  padding: 0 20px;
}

/* 4. Fjern eventuelle skygger og borders */
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 500px;
  overflow: visible;
  width: 100%;
  border-radius: var(--border-radius-md);
  background-color: transparent; /* Transparent wrapper */
  border: none;
  box-shadow: none;
}

/* Fikse forsvinningsproblem med bilder */
.carousel-image {
  max-width: 85%;
  max-height: 85%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: var(--border-radius-sm);
  box-shadow: none;
  transition:
    transform var(--transition-medium),
    opacity var(--transition-medium);
  transform: scale(0.95);
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: var(--border-radius-sm);
}

/* Sørg for at aktive bilder vises */
.carousel-slide.active .carousel-image {
  opacity: 1;
  transform: scale(1);
}

/* Oppdatere carousel-image-wrapper - renere bakgrunn */
.carousel-image-wrapper {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
  background-color: rgba(85, 131, 146, 0.08); /* Litt mørkere bakgrunn */
  border-radius: var(--border-radius-sm);
  flex: 1;
  padding: 20px;
  overflow: hidden;
}

/* Fjern gradient overlay fra image wrapper */
.carousel-image-wrapper::after {
  display: none; /* Fjern gradient overlay */
}

/* Oppdatere carousel-text for en mer minimalistisk stil */
.carousel-text {
  flex: 0 0 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.8); /* Transparent hvit bakgrunn */
  border-radius: var(--border-radius-sm);
  /* Svært lett skygge for å skille fra bakgrunn */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
}

/* Juster carousel-slide-title for å matche den nye, renere stilen */
.carousel-slide-title {
  font-size: 2rem; /* Større tittel */
  margin-bottom: 20px;
  color: var(--text-dark);
  font-weight: 700;
  transition: all var(--transition-medium);
  position: relative;
  padding-bottom: 15px;
  color: var(--text-dark);
  /* Legg til en subtil understrek for å framheve tittel */
  border-bottom: 2px solid rgba(85, 131, 146, 0.15);
}

/* Sørg for at aktive titler vises korrekt */
.carousel-slide.active .carousel-slide-title {
  opacity: 1;
  transform: translateY(0);
}

/* Forbedre lesbarhet for beskrivelsestekst */
.carousel-description {
  font-size: 1.1rem; /* Større beskrivelse */
  line-height: 1.6;
  color: var(--text-medium);
  transition: all var(--transition-medium);
  opacity: 0;
  transform: translateY(20px);
}

/* Sørg for at aktive beskrivelser vises */
.carousel-slide.active .carousel-description {
  opacity: 1;
  transform: translateY(0);
}

/* Fikse navigasjonspilene */
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(85, 131, 146, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 20;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

/* Behold disse verdiene for knappene */
.carousel-arrow-left {
  left: -25px; /* Holder knappen utenfor carousellen */
}

.carousel-arrow-right {
  right: -25px; /* Holder knappen utenfor carousellen */
}

.carousel-arrow:hover {
  background-color: var(--primary-color);
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Flyttet lengre ut med negative verdier */
.carousel-arrow-left {
  left: -25px; /* Holder knappen utenfor carousellen */
}

.carousel-arrow-right {
  right: -25px; /* Holder knappen utenfor carousellen */
}

/* Tillegg for å justere på mindre skjermer */
@media (max-width: 1250px) {
  /* For skjermer som er litt mindre, men fortsatt desktop */
  .carousel-arrow-left {
    left: -15px;
  }

  .carousel-arrow-right {
    right: -15px;
  }
}

/* På mobile enheter plasserer vi dem fortsatt innenfor */
@media (max-width: 768px) {
  .carousel-arrow-left {
    left: 10px;
  }

  .carousel-arrow-right {
    right: 10px;
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-light);
  border: none;
  cursor: pointer;
  transition: var(--transition-fast);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.carousel-dot.active,
.carousel-dot:hover {
  background-color: var(--accent-color);
  transform: scale(1.2);
  background-color: var(--primary-color);
  transform: scale(1.2);
}

/* Responsive styles for the carousel */
@media (max-width: 992px) {
  .carousel-wrapper {
    height: 450px;
  }

  .carousel-text {
    padding: 30px 40px;
  }

  .carousel-slide-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .landing-container,
  .feature-carousel-container,
  .landing-midtext {
    padding: 2rem 1rem;
  }

  .carousel-wrapper {
    height: auto;
  }

  .carousel-slide {
    flex-direction: column;
    height: auto;
    position: relative;
    overflow: hidden; /* Prevent content from breaking layout */
    position: relative; /* Not absolute for mobile */
    flex-direction: column;
    height: auto;
    transform: none !important; /* Override inline transform */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease;
  }

  .carousel-slide.active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
  }

  .carousel-image-wrapper,
  .carousel-text {
    flex: 0 0 100%;
    width: 100%;
    width: 100%;
    height: 180px; /* Fixed height for image container */
    padding: 15px;
  }

  /* Use aspect-ratio instead of fixed height */
  .carousel-image-wrapper {
    height: auto;
    min-height: 200px; /* Minimum height */
    aspect-ratio: 16/9; /* Maintain consistent aspect ratio */
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-image {
    max-height: 100%;
    max-width: 100%;
    border-radius: var(--border-radius-sm);
    max-height: 150px;
    width: auto;
  }

  /* Keep carousel navigation aligned with the image container center */
  .carousel-arrow {
    top: 120px; /* Vertically centered with the image container */
  }

  /* Hide overflowing content during transitions */
  .carousel-content {
    overflow: hidden;
  }

  /* Fix slide transition for mobile */
  .carousel-slide {
    transition: transform var(--transition-medium);
    transform: translateX(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  /* Only the active slide should be visible */
  .carousel-slide:not(.active) {
    visibility: hidden;
    opacity: 0;
  }

  .carousel-slide.active {
    visibility: visible;
    opacity: 1;
  }

  .carousel-text {
    padding: 25px 20px 35px;
    width: 100%;
    padding: 20px;
  }

  .carousel-slide-title {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }

  .carousel-arrow {
    top: 125px;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  .carousel-arrow-left {
    left: 10px;
  }
  .carousel-arrow-right {
    right: 10px;
  }

  .midtext-sponsors-cta {
    padding: 1rem;
    font-size: 1rem;
  }

  .newsletter-form-container {
    padding: 1.5rem;
    margin: 1.5rem 1rem;
  }

  .carousel-dots {
    margin-top: 15px;
  }

  /* Mobile-friendly carousel fixes that ensure text isn't cut off */

  /* Update the carousel content and slides for dynamic height */
  .carousel-content {
    height: auto !important; /* Override any fixed height */
    min-height: 400px; /* Starting height that will expand */
  }

  .carousel-slide {
    flex-direction: column;
    position: relative !important; /* Not absolute to allow normal flow */
    height: auto !important; /* Let content determine height */
    opacity: 0;
    visibility: hidden;
    display: none; /* Hide non-active slides completely */
    transform: none !important; /* Remove transform that could affect layout */
  }

  .carousel-slide.active {
    display: flex !important; /* Show active slide */
    visibility: visible;
    opacity: 1;
    position: relative !important;
    height: auto !important;
  }

  .carousel-image-wrapper {
    height: auto !important;
    min-height: 180px;
    padding: 15px;
    width: 100%;
  }

  .carousel-text {
    padding: 20px 15px 25px;
    width: 100%;
    height: auto !important; /* Allow text container to expand */
    min-height: 0 !important;
  }

  .carousel-description {
    overflow: visible !important; /* Ensure text is never cut off */
    display: block !important;
    height: auto !important;
    white-space: normal !important;
  }

  /* Position arrows relative to the image section */
  .carousel-arrow {
    top: 100px; /* Position relative to top of slide rather than centered */
  }
}

/* Small mobile devices adjustments */
@media (max-width: 480px) {
  .carousel-content {
    min-height: 350px;
  }

  .carousel-image-wrapper {
    min-height: 150px;
  }

  .carousel-text {
    padding: 15px 15px 20px;
  }

  /* Smaller arrows positioned appropriately */
  .carousel-arrow {
    width: 36px;
    height: 36px;
    font-size: 16px;
    top: 80px;
  }

  /* Make description text slightly smaller but still fully visible */
  .carousel-description {
    font-size: 0.95rem;
    line-height: 1.5;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .carousel-image-wrapper {
    height: 150px;
  }

  .carousel-image {
    max-height: 120px;
  }

  .carousel-text {
    padding: 15px;
  }

  .carousel-slide-title {
    font-size: 1.1rem;
  }

  .carousel-arrow {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
}

/* Hero Text Styling */
.hero-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 0;
}

.hero-title {
  font-size: 2.6rem;
  font-weight: 800;
  line-height: 1.2;
  color: var(--text-dark);
  margin-bottom: 1rem;
  position: relative;
  animation: fadeUp 0.8s ease-out forwards;
}

.hero-subtitle {
  font-size: 1.4rem;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 1.5rem;
  animation: fadeUp 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.hero-description {
  font-size: 1.15rem;
  line-height: 1.7;
  color: var(--text-medium);
  margin-bottom: 2rem;
  max-width: 540px;
  animation: fadeUp 0.8s ease-out 0.4s forwards;
  opacity: 0;
}

.brand-name {
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 0.5px;
}

.highlight {
  color: var(--accent-color);
  font-weight: 700;
  position: relative;
  padding: 0 2px;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 152, 0, 0.2);
  z-index: -1;
  transform: skewX(-5deg);
}

.hero-cta {
  margin-top: 1rem;
  animation: fadeUp 0.8s ease-out 0.6s forwards;
  opacity: 0;
}

.hero-btn {
  padding: 0.9rem 2rem;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 20px rgba(255, 152, 0, 0.3);
  transition:
    transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 0.3s ease;
  text-decoration: none; /* Add this to remove underline */
  display: inline-block; /* Ensure it maintains button-like dimensions */
}

.hero-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(255, 152, 0, 0.4);
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1100px) {
  .hero-title {
    font-size: 2.2rem;
    text-align: center;
  }

  .hero-subtitle,
  .hero-description {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-cta {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .hero-title {
    font-size: 1.8rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .hero-description {
    font-size: 1rem;
  }
}
