/* Import en moderne font fra Google Fonts hvis ønskelig */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

/* Add this new class for the navbar wrapper */
.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent; /* Make wrapper transparent to show page background */
  position: relative;
  z-index: 1000; /* Higher than any content */
}

.navbar {
  width: 70%; /* This sets the navbar to take approximately columns 2-4 of a 6-column grid */
  max-width: 1000px; /* Optional: set a max width for very large screens */
  background: rgba(255, 255, 255, 0.2); /* More transparent to match SignUp */
  backdrop-filter: blur(10px); /* Creates frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  padding: 0.7rem 1.5rem;
  /* Updated shadow with brand color */
  box-shadow: 0px 4px 30px rgba(85, 131, 146, 0.2); /* Using #558392 with 0.2 opacity */
  border-radius: 20px; /* Fully rounded like SignUp */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Match SignUp border */
  /* Add a subtle animation when the page loads */
  animation: navbarAppear 0.4s ease-out forwards;
  transform-origin: top center;
  padding-bottom: 20px;
  position: relative;
  z-index: 1000; /* Higher than any content */
}

/* Update the animation end state with the new shadow color */
@keyframes navbarAppear {
  0% {
    transform: translateY(-10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(85, 131, 146, 0);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0px 4px 30px rgba(85, 131, 146, 0.2);
  }
}

/* Update hover effect with stronger brand color shadow */
.navbar:hover {
  box-shadow: 0px 6px 35px rgba(85, 131, 146, 0.3);
  transition: box-shadow 0.3s ease;
}

.navbar .container {
  width: 100%;
  max-width: 100%;
}

.navbar .navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: #000000;

  background-repeat: no-repeat;
  background-position: 1% center; /* Juster første verdi: 
                                      0% = helt til venstre
                                      50% = midt i
                                      100% = helt til høyre 
                                      Eller bruk px: '20px center' */
  background-size: 50px; /* Større bakgrunnsbilde */
  padding: 10px 20px; /* Padding rundt teksten */
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8); /* Gjør teksten mer lesbar */
  opacity: 1; /* Juster denne verdien mellom 0 og 1 for å endre styrken */
  /* ELLER bruk denne metoden istedenfor opacity: */
  background-color: rgba(
    248,
    249,
    250,
    0.2
  ); /* Juster siste tall (0.7) mellom 0 og 1 */
  background-blend-mode: lighten; /* Dette blander bakgrunnsbildet med fargen */
}

.navbar .nav-link {
  color: #2d3748;
  margin-right: 1.5rem;
  font-weight: 500;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.navbar .nav-link:hover {
  color: #558392;
  background-color: rgba(49, 130, 206, 0.08);
  transform: translateY(-2px);
}

.navbar .nav-link.active {
  color: #3182ce;
  font-weight: 600;
  position: relative;
}

.navbar .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #3182ce, #63b3ed);
  border-radius: 2px;
}

.navbar .navbar-signin,
.navbar .navbar-signup {
  margin-right: 0.5rem;
}

.navbar .navbar-signup {
  background: linear-gradient(90deg, #4a90e2, #63b3ed);
  color: white !important;
  padding: 0.5rem 1.2rem;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(74, 144, 226, 0.3);
}

.navbar .navbar-signup:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(74, 144, 226, 0.4);
  background: linear-gradient(90deg, #3182ce, #4a90e2);
}

.navbar .dropdown-menu {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0.5rem;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1001; /* Even higher to be above navbar itself */
}

.navbar .dropdown-item {
  border-radius: 8px;
  padding: 0.6rem 1rem;
  color: #2d3748;
  font-weight: 500;
  transition: all 0.2s ease;
}

.navbar .dropdown-item:hover {
  background-color: rgba(49, 130, 206, 0.1);
  color: #3182ce;
}

/* Justering for språkvelgeren */
.navbar .align-items-center {
  display: flex;
  flex-direction: row; /* Ensure horizontal layout */
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
  height: 100%;
}

.navbar .language-selector {
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 100%; /* Match the height of other nav items */
}

/* Add this to ensure the dropdown button aligns properly */
.navbar .dropdown-toggle {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 1000px) {
  .navbar {
    width: 90%; /* Make navbar wider on small screens */
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar-nav {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .navbar-nav .nav-link {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .navbar-nav .nav-link:last-child {
    margin-bottom: 0;
  }

  .navbar .language-selector {
    margin: 0 0 0 10px; /* Remove top/bottom margin in mobile view */
    height: auto; /* Let height be determined by content on mobile */
  }

  /* This keeps items in the same row even on mobile */
  .navbar .align-items-center {
    flex-direction: row; /* Keep horizontal layout */
    justify-content: center; /* Center items on mobile */
  }
}
