.payment-cancelled-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  padding: 20px;
}

.payment-cancelled-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  border-left: 4px solid #ff9800;
}

.payment-cancelled-card h2 {
  color: #ff9800;
  margin-bottom: 20px;
}

.payment-cancelled-card p {
  margin-bottom: 30px;
  color: #666;
  line-height: 1.6;
}

.payment-cancelled-card button {
  background-color: #ff9800;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-cancelled-card button:hover {
  background-color: #f57c00;
}
