.legal-container {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: #f8f9fa;
  min-height: calc(100vh - 80px);
}

.legal-content {
  max-width: 800px;
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

.legal-content h1 {
  color: #333;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.legal-content h2 {
  color: #333;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.legal-content h3 {
  color: #555;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.legal-content p {
  margin-bottom: 1rem;
}

.legal-content ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.legal-content li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .legal-content {
    padding: 1.5rem;
  }
}
