.dropdown-button-custom {
  border-radius: 8px;
  background-color: #f8f9fa !important; /* Fargen på bakgrunnen */
  color: #558392 !important;
  display: flex;
  align-items: center;
  padding: 8px;
  transition:
    background-color 0.3s,
    color 0.3s; /* For jevn overgang */
}
.dropdown-button-custom .dropdown-toggle {
  border: none;
  background-color: #f8f9fa !important;
  color: #558392 !important;
}
.dropdown-button-custom:hover,
.dropdown-button-custom:focus,
.dropdown-button-custom:active {
  background-color: #f8f9fa !important; /* Bakgrunnen forblir uendret */
  color: orange !important; /* Knappen endrer farge til orange */
}

.dropdown-button-custom.show .dropdown-toggle {
  background-color: #f8f9fa !important; /* Uendret bakgrunn */
  color: black !important; /* Knappen skal forbli sort */
}

.dropdown-button-custom .dropdown-toggle:focus {
  background-color: #f8f9fa !important; /* Sørg for at bakgrunnen ikke endres */
  outline: none; /* Fjern fokuslinjen */
  box-shadow: none; /* Fjern fokus-skygge */
}
.dropdown-button-custom.show .dropdown-toggle {
  background-color: #f8f9fa !important; /* Change to  #f8f9fa when dropdown is open */
  color: black;
}

.dropdown-button-custom .dropdown-toggle:focus {
  background-color: #f8f9fa !important; /* Ensure focus color stays  */
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove default focus shadow */
}

.dropdown-item-custom {
  color: black !important;
  transition:
    background-color 0.3s,
    color 0.3s; /* Jevn overgang */
}

.dropdown-item-custom:hover,
.dropdown-item-custom:focus {
  background-color: #f0f0f0 !important;
  color: orange !important;
}

.language-flag {
  width: 20px;
  margin-right: 8px;
}
