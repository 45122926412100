.custom-google-btn {
  background-color: transparent; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  padding: 0; /* Remove padding */
  display: inline-flex; /* Flexbox for centering image */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  transition: transform 0.3s ease; /* Smooth transition for hover */
}

.custom-google-btn img {
  width: 100%; /* Full width of the button */
  max-width: 50px; /* Initial size of the button */
  transition: transform 0.3s ease; /* Smooth transition for image */
}

.custom-google-btn:hover img {
  transform: scale(1.1); /* Scale image on hover */
}

.custom-google-btn:disabled {
  opacity: 0.6; /* Reduced opacity for disabled state */
  cursor: not-allowed; /* Not allowed cursor for disabled state */
}
