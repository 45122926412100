:root {
  --assignee-color-1: #ff8a65; /* Farge for assignee 1 */
  --assignee-color-2: #4caf50; /* Farge for assignee 2 */
  --assignee-color-3: #2196f3; /* Farge for assignee 3 */
  --assignee-color-4: #ffeb3b; /* Farge for assignee 4 */
  --assignee-color-5: #9c27b0; /* Farge for assignee 5 */
  /* Legg til flere farger etter behov */
}

/* Generelle layout- og containerstiler */
.main-content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 250px); /* Juster høyden basert på dine behov */
  margin-bottom: 100px; /* Legg til margin for å unngå at innhold skjules bak knappene */
}

.gantt-chart-wrapper,
.table-container,
.scroll-buttons {
  display: flex; /* Bruker flexbox for layout */
  flex-direction: column; /* Innhold legges vertikalt */

  height: 600px; /* Sørg for at containeren fyller hele høyden */
  max-height: 950px;
}

.gantt-container-wrapper {
  flex: 1; /* Definerer størrelsesforholdet til denne containeren */
  position: relative; /* Brukes for absolutt posisjonering av barer */
  font-size: 12px; /* Mindre skriftstørrelse for diagrammet */
  margin: 0; /* Ingen ytre margin */
  padding: 0; /* Ingen indre margin */
  max-height: 800px;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
  pointer-events: auto;
}

.gantt-container {
  height: calc(100vh - 140px);
  min-height: 400px;
  max-height: calc(100vh - 140px);
  overflow-y: hidden;
  overflow-x: auto;
  border-left: 1px solid #f0f0f0;
  border-radius: 0;
  margin-left: -1px;
  flex: 1;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transition: margin-left 0.5s ease;
  position: relative;
  z-index: 1;
}

.gantt-container::-webkit-scrollbar {
  display: none;
}

.gantt-chart-container {
  flex-grow: 1; /* Gantt-diagrammet tar opp all tilgjengelig plass */
  position: absolute;
}
.gantt-chart {
  flex: 1; /* Fyller all tilgjengelig plass som er igjen */
  overflow-x: scroll;
}
#gantt {
  position: absolute;
  margin: 0; /* Fjerner ytre margin */
  padding: 0; /* Fjerner indre margin */
  height: 100%; /* Fyller hele høyden */
  width: 100%; /* Fyller hele bredden */
  overflow-x: scroll;
}

/* Stil for oppgavebarene i Gantt-diagrammet */
.gantt .bar {
  fill: var(--bar-color) !important; /* Bruker variabel for bar-farge */
  stroke: var(--bar-stroke); /* Bruker variabel for bar-kantlinje */
  stroke-width: 0; /* Ingen kantlinje som standard */
  transition: stroke-width 0.3s ease; /* Glidende overgang for kantlinje */
  height: 30px; /* Juster høyden til ønsket verdi */
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0 0 2px rgba(17, 43, 66, 0.16));
  border-radius: 3px;
}

.gantt .bar.assignee-1 {
  fill: var(--assignee-color-1) !important;
}

.gantt .bar.assignee-2 {
  fill: var(--assignee-color-2) !important;
}

.gantt .bar.assignee-3 {
  fill: var(--assignee-color-3) !important;
}

.gantt .bar.assignee-4 {
  fill: var(--assignee-color-4) !important;
}

.gantt .bar.assignee-5 {
  fill: var(--assignee-color-5) !important;
}

/* Legg til flere stiler for flere assignees etter behov */

.gantt .bar-progress {
  fill: var(
    --progress
  ) !important; /* Bruker variabel for fremdriftsindikasjon */
}

.gantt .bar-wrapper:hover .bar-progress {
  fill: rgba(104, 159, 186, 0.5); /* Sterkere farge når brukeren svever over */
}

.gantt .bar-wrapper.active .bar-progress {
  fill: var(--dark-blue); /* Farge for aktiv fremdrift */
}

/* Stil for håndtakene for å justere fremdriften på en oppgave */
.gantt .handle {
  fill: var(--handle-color); /* Bruker variabel for håndtak-farge */
  cursor: ew-resize; /* Endre markøren til en horisontal resize-markør */
  opacity: 0; /* Skjuler håndtakene som standard */
  visibility: hidden; /* Skjuler håndtakene som standard */
  transition: opacity 0.3s ease; /* Glidende overgang for synlighet */
}

.gantt .handle.progress {
  fill: var(--text-muted); /* Bruker variabel for fremdriftshåndtakene */
}

.gantt .bar-wrapper:hover .handle {
  visibility: visible; /* Sørg for at håndtakene er synlige ved hover */
  opacity: 1; /* Gjør håndtakene helt synlige ved hover */
}

.gantt .bar-wrapper.active .handle {
  visibility: visible; /* Sørg for at håndtakene er synlige når aktiv */
  opacity: 1; /* Gjør håndtakene helt synlige når aktiv */
}

.gantt-container-wrapper .popup-wrapper .title {
  border-bottom: 3px solid #ff8a65; /* Korallfarge for popup-titler */
  padding: 10px; /* Litt polstring for tittelområdet */
}
.gantt-container-wrapper .grid-header {
  background-color: #fff; /* Beholder den eksisterende fargen */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid var(--border-color-dark); /* Legger til en bottom border */
}

.gantt-container-wrapper .current-highlight {
  position: absolute;
  background: Red !important; /* Velg din ønskede farge, for eksempel rød */
  width: 2px;
}
.gantt-container-wrapper .current-date-highlight {
  background: Orange !important; /* Endrer farge på markeringen for dagens dato */
  color: var(--text-light);
  padding: 4px 8px;
  border-radius: 200px;
}
/* Knapper */

.button-container {
  display: flex; /* Make all buttons appear on the same row */
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Align buttons vertically */
  gap: 10px; /* Add spacing between the buttons */
  padding: 10px;
}

.btn-add {
  background-color: rgb(
    96,
    125,
    139
  ); /* Blågrå bakgrunnsfarge for visningsknappene */
  color: white; /* Hvit tekst */
  border: none; /* Ingen kantlinje */
  padding: 0 10px; /* Innvendig polstring */
  margin: 5px; /* Ytre margin */
  cursor: pointer; /* Markøren endres til en peker */
  border-radius: 4px; /* Litt avrundede hjørner */
  font-size: 14px; /* Mellomstor tekststørrelse */
  height: 40px;
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
}
/* Knapper */
.button-container {
  display: flex;
  justify-content: space-between; /* Spread view buttons to the left, action buttons centered */
  align-items: center; /* Align buttons vertically */
  width: 100%; /* Full width to span the container */
  padding: 0px 0; /* Remove excessive vertical padding */
  box-sizing: border-box;
  margin-bottom: 0px; /* Add some space between the buttons and the Gantt chart */
  flex-direction: row-reverse; /* Endrer rekkefølgen på flex-elementer */
}
button {
  padding: 0px 0px; /* Control the padding to avoid large buttons */
  font-size: 16px;
  border: none;
  background-color: #607d8b;
  color: white;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
}
.ViewButtons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end; /* Justerer til høyre */
}
.button-container-icons {
  display: flex;
  justify-content: flex-start; /* Justerer til venstre */
  align-items: center; /* Vertically align action buttons */
  gap: 10px;
  flex-grow: 1; /* Allows the action buttons to stay centered */
  padding-left: 40px; /* Øker padding fra 20px til 40px */
}
.btn-view {
  background-color: #f0f2f5; /* Lys grå bakgrunnsfarge for visningsknappene */
  color: #65676b; /* Mørk grå tekst */
  border: none; /* Ingen kantlinje */
  padding: 8px 12px; /* Innvendig polstring */
  margin: 5px; /* Ytre margin */
  cursor: pointer; /* Markøren endres til en peker */
  border-radius: 20px; /* Avrundede hjørner */
  font-size: 16px; /* Mellomstor tekststørrelse */
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-view:hover {
  background-color: #e4e6e9; /* Mørkere grå bakgrunn ved hover */
  color: #5c5d5f; /* Mørkere tekst ved hover */
}

.btn-active {
  background-color: orange !important; /* Korallfarge for aktiv knapp */
  color: white !important; /* Hvit tekst som alltid er synlig */
}

/* Stil for lagre, avbryt og slette knapper */
.btn-save,
.btn-cancel,
.btn-delete,
.btn-newactivity {
  background-color: white; /* Hvit bakgrunn */
  border: none; /* Ingen kantlinje */
  padding: 0 10px; /* Innvendig polstring */
  margin-left: 25px; /* Ytre margin på venstre side */
  cursor: pointer; /* Peker når knappene er interaktive */
  font-size: 35px; /* Stor skriftstørrelse */
  color: black;
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
}

.btn-save:hover,
.btn-cancel:hover,
.btn-delete:hover,
.btn-newactivity:hover {
  background-color: white;
  color: orange; /* Oransje tekst ved hover */
}

.table-container {
  height: calc(100vh - 250px);
  min-height: 400px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 500px;
  max-width: 500px;
  flex-shrink: 0;
  transition: all 1s ease;
  transition-delay: 1.8s;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  position: relative;
  z-index: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-container::-webkit-scrollbar {
  display: none;
}

.table-container:hover {
  width: 1200px;
  max-width: 1200px;
}

.scroller {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed; /* Change from absolute to fixed */
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000; /* Ensure buttons are above other elements */
  pointer-events: auto; /* Ensure clicks are registered */
}

.scroll-button {
  background-color: orange; /* Button background color */
  border: none; /* Remove default border */
  border-radius: 50%; /* Makes the button round */
  width: 40px; /* Button width */
  height: 40px; /* Button height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Change cursor on hover */
  margin: 5px 0; /* Space between buttons */
  transition: background-color 0.3s;
  z-index: 1000; /* Ensure buttons are above other elements */
  pointer-events: auto; /* Ensure clicks are registered */
  opacity: 0.8; /* Make buttons slightly transparent */
}

.scroll-button:hover {
  background-color: #607d8b; /* Darker background on hover */
  opacity: 1; /* Full opacity on hover */
}

/* Tabellstiler */
.table {
  min-width: 1100px; /* Sett en minimumsbredde på tabellen for å tvinge horisontal scrolling */
  border-collapse: collapse; /* Fjerner mellomrom mellom celler */
  width: auto; /* Tillat tabellen å tilpasse seg innholdet */
  table-layout: fixed; /* Tving tabellen til å bruke faste kolonnebredder */
  background-color: white;
  border-radius: 8px;
  overflow: hidden; /* For å bevare border-radius */
}

.table thead {
  position: sticky; /* Gjør headeren sticky */
  border-bottom: 1px solid #a9a8a8; /* Lys grå kantlinje for tabellhodet */
  top: 0;
  z-index: 1; /* Sikrer at headeren er over innholdet */
  border-top: white;
  background-color: #f8f9fa; /* Match Gantt chart header background color */
}

.table th {
  position: sticky; /* Gjør headeren sticky */
  height: 74px; /* Fast høyde for headeren */
  background-color: #f8f9fa !important; /* Match Gantt chart header background color */
  vertical-align: center; /* Tekst midtjustert vertikalt */
  font-size: 13px; /* Liten tekststørrelse */
  text-align: left; /* Venstrejustert tekst */
  padding: 6px 12px;
  color: #558392 !important; /* Match Gantt chart header text color */
  font-weight: 600;
  border-bottom: 1px solid #9e9fa1 !important;
  transition: background-color 0.3s ease;
}

.table td {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  height: 48px !important; /* Ensure fixed height */
  line-height: 24px !important; /* Ensure line height matches height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */

  font-size: 12px; /* Liten tekststørrelse */
  text-align: left; /* Venstrejustert tekst */
  font-family: 'Roboto', sans-serif; /* Legger til Roboto-fonten */
  vertical-align: middle; /* Legger til vertikal sentrering */
  min-height: 48px !important;
  max-height: 48px !important;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}

.table tbody tr:hover {
  background-color: #f8f9fa;
}

.table tbody tr {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  height: 48px !important; /* Ensure fixed height */
  line-height: 48px !important; /* Ensure line height matches height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: both; /* Remove border */
  min-height: 48px !important;
  max-height: 48px !important;
  border: both;
}

/* Kolonnestiler med faste bredder */
.col-id {
  width: 4%;
}

.col-navn {
  width: 7%; /* Juster bredde for navnekolonnen */
}

.col-start {
  width: 5%;
}

.col-slutt {
  width: 5%;
}

.col-varighet {
  width: 5%;
}

.col-fremdrift {
  width: 5%;
}

.col-avhengigheter {
  width: 8%; /* Bredde for avhengighetskolonnen */
}

.col-klassifisering {
  width: 8%;
}

.col-tildelt {
  width: 8%;
}

/* Stiler for input-felt i cellene */
.table input {
  border: none; /* Ingen kantlinje på input-feltene */
  width: 100%; /* Input-feltene fyller hele bredden av cellen */
  padding: 1px; /* Ingen indre polstring */
  box-sizing: border-box; /* Inkluder padding i elementets totale bredde */
  outline: none;
  height: 100%; /* Får input til å fylle hele cellehøyden */
  vertical-align: middle; /* Sentrerer input-teksten vertikalt */
  background: transparent;
  font-size: 12px;
  color: #495057;
  transition: all 0.2s ease;
}
.table input:focus {
  background-color: rgba(255, 165, 0, 0.1); /* Lysere oransje bakgrunn */
  border-radius: 4px;
  padding: 0 8px;
}

.table input,
.table select {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  height: 100%; /* Ensure input fields fill the cell height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  height: 48px !important;
  min-height: 48px !important;
  max-height: 48px !important;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.table select {
  width: 100%;
  height: 32px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
}

.table select:hover {
  border-color: #558392;
}

@keyframes pendingPulse {
  0% {
    background-color: #f0f2f5;
    color: #65676b;
  }
  50% {
    background-color: orange;
    color: white;
  }
  100% {
    background-color: #f0f2f5;
    color: #65676b;
  }
}

.btn-icon.btn-pending {
  animation: pendingPulse 2s infinite;
}

.btn-icon.btn-pending:hover {
  animation: none;
  background-color: #e69500;
  color: white;
}

@media (max-width: 1000px) {
  .main-content {
    flex-direction: column; /* Endre til kolonne for mobilvisning */
    height: 600px; /* Juster høyden for mobilvisning */
  }

  .gantt-container,
  .table-container {
    width: 100%; /* Full bredde for både Gantt-diagrammet og tabellen */
    max-width: 100%; /* Ingen horisontal scrolling */
    height: 350px;
    min-height: 0;
    overflow-x: auto;
    border-left: none;
  }

  /* Button-container */
  .button-container {
    flex-direction: column; /* Endre til kolonne for mobilvisning */
    gap: 10px; /* Mellomrom mellom rader */
  }

  /* Rad for visningsknappene */
  .ViewButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 5px; /* Mellomrom mellom radene */
  }

  /* Rad for handlingene */
  .button-container-icons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  /* Plasser tabellen i full bredde */
  .table-container {
    order: 3; /* Gjør at tabellen kommer under knappene */
    max-height: none; /* Fjern maksimal høyde for bedre tilpasning */
  }

  /* Plasser Gantt Chart under tabellen */
  .gantt-container {
    width: 100%; /* Full bredde */
    order: 1; /* Gantt-diagrammet skal komme først */
    max-height: none; /* Fjern maksimal høyde for bedre tilpasning */
  }
}

.btn-icon {
  background-color: #f0f2f5;
  border: none;
  color: #65676b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 50px;
  height: 37px;
  gap: 5px;
  padding: 8px 12px;
  border-radius: 20px;
  margin-left: 5px;
}

.btn-icon:hover {
  background-color: #e4e6e9; /* Hover effect for buttons */
  color: #5c5d5f; /* Darker text color on hover */
}

.btn-icon.btn-pending {
  background-color: orange;
  color: white;
}

.btn-icon.btn-pending:hover {
  background-color: #e69500; /* En mørkere nyanse av oransje */
  color: white;
}

.toast-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.toast-buttons button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.toast-buttons button:first-child {
  background-color: #558392;
  color: white;
}

.toast-buttons button:last-child {
  background-color: #6c757d;
  color: white;
}

.toast-buttons button:hover {
  opacity: 0.9;
}

.bottom-actions {
  display: none;
}

.bottom-action-btn {
  display: none;
}

@media (max-width: 768px) {
  .gantt-page-layout {
    padding: 10px;
  }

  .main-content {
    flex-direction: column;
    height: auto;
    margin-bottom: 120px;
  }

  .button-container {
    flex-direction: column;
    padding: 5px;
  }

  .ViewButtons {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }

  .button-container-icons {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    margin-top: 10px;
  }

  .btn-view {
    font-size: 14px;
    padding: 6px 10px;
  }

  .btn-icon {
    width: 40px;
    height: 32px;
    font-size: 14px;
  }

  .table-container {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
  }

  .table {
    font-size: 11px;
    min-width: 800px;
  }

  .table th {
    height: 50px;
    padding: 4px 6px;
    font-size: 11px;
  }

  .table td {
    height: 40px;
    padding: 2px 6px;
  }

  .gantt-container {
    height: 400px;
    width: 100%;
    overflow-x: auto;
  }

  .bottom-actions {
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
  }

  .bottom-action-btn {
    min-width: 120px;
    font-size: 12px;
    padding: 8px 12px;
  }

  .scroll-button {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 480px) {
  .table {
    min-width: 600px;
  }

  .bottom-action-btn {
    min-width: calc(50% - 10px);
    font-size: 11px;
  }

  .gantt-container {
    height: 300px;
  }
}

.undo-counter {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.undo-button {
  position: relative;
}

.undo-counter {
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}

.gantt .holiday-highlight {
  fill: url(#holiday-pattern) !important;
}

/* Add these styles for the today button */

/* Add styles for selected task */
.table tr.selected {
  background-color: rgba(85, 131, 146, 0.1);
}

.table tr.selected:hover {
  background-color: rgba(85, 131, 146, 0.15);
}

/* Stil for popup-vinduer */
.gantt-container-wrapper .popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: #171b1f;
  padding: 10px;
  border-radius: 5px;
  width: max-content;
}

.gantt-container-wrapper .popup-wrapper.hidden {
  opacity: 0 !important;
}

.gantt-container-wrapper .popup-wrapper .title {
  margin-bottom: 5px;
  text-align: center;
  color: var(--text-light);
}

.gantt-container-wrapper .popup-wrapper .subtitle {
  color: var(--text-grey);
}

.gantt-container-wrapper .popup-wrapper .pointer {
  position: absolute;
  height: 5px;
  margin: 0 0 0 -5px;
  border: 5px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.8);
}

.assignee-cell {
  min-width: 200px;
  padding: 4px 8px !important;
  position: relative;
  height: 46px !important;
  min-height: 46px !important;
  max-height: 46px !important;
}

.assignee-cell .task-assignee-wrapper {
  height: 100%;
  position: relative;
}

.assignee-cell .current-assignee {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  height: 38px !important;
  cursor: pointer;
  border-radius: 4px;
}

.assignee-cell .search-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 46px !important;
  background: white;
  z-index: 100;
  padding: 0 4px;
}

.assignee-cell .search-container .search-header {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100% !important; /* Match parent height */
  padding: 4px;
  background: white;
}

.assignee-cell .search-container .search-input {
  height: 28px !important; /* Redusert høyde */
  font-size: 0.8rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 0 8px;
  width: calc(100% - 32px); /* Gjør plass til X-knappen */
  margin: auto 0; /* Sentrerer vertikalt */
}

.assignee-cell .search-container .close-search-btn {
  width: 28px;
  height: 28px !important;
  padding: 0;
  margin: auto 0; /* Sentrerer vertikalt */
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  flex-shrink: 0;
}

.assignee-cell .search-container .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}
