.custom-magic-link-btn {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 20px 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.magic-link-content {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  padding: 8px 15px !important;
  background-color: #ffffff !important; /* Match Microsoft button color */
  border-radius: 5px !important;
  border: 1px solid #eaeaea !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08) !important;
  box-sizing: border-box !important;
  transition: all 0.3s ease !important;
  cursor: pointer;
}

.magic-link-content:hover {
  background-color: #558392 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12) !important;
}

.magic-link-icon-text {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.custom-magic-link-btn img.btn-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

.magic-link-content:hover img.btn-icon {
  transform: scale(1.05);
}

.magic-link-text {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 10px;
  flex-shrink: 0;
}

.magic-link-content:hover .magic-link-text {
  color: white;
}

/* Make the input field seamlessly integrated */
.magic-link-input {
  flex-grow: 1;
  color: #333;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  padding: 8px 0;
  font-size: 14px;
  min-width: 0;
  margin-left: 8px;
  text-align: left;
  text-decoration: underline;
}

.magic-link-input::placeholder {
  color: #777;
  opacity: 1;
  text-decoration: none; /* No underline for placeholder text */
  display: block !important;
}

.magic-link-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-bottom: none !important;
}

.magic-link-content:hover .magic-link-input {
  color: white;
}

.magic-link-content:hover .magic-link-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.magic-link-input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-magic-link-btn:disabled,
.custom-magic-link-btn:disabled .magic-link-content {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Remove the old submit button styles that are no longer needed */
.magic-link-submit,
.magic-link-submit-icon,
.magic-link-icon-button {
  display: none;
}
