:root {
  --primary-color: #558392; /* Blue-teal as primary brand color */
  --primary-light: rgba(85, 131, 146, 0.1);
  --primary-medium: rgba(85, 131, 146, 0.4);
  --accent-color: #ff9800; /* Orange as accent color */
  --accent-hover: #ffb74d;
  --text-dark: #2c3e50;
  --text-medium: #546e7a;
  --text-light: #ffffff;
  --bg-light: #ffffff;
  --bg-medium: #f5f7f9;
  --shadow-soft: 0 5px 15px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --transition-fast: 0.2s ease;
  --transition-medium: 0.3s ease;
  --font-family: 'Roboto', sans-serif;
}

.sidebar {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.following-section {
  margin-top: 20px;
  border-top: 1px solid var(--primary-light);
  padding-top: 20px;
}

.following-section h3 {
  margin-bottom: 15px;
  color: var(--text-medium);
  font-size: 1rem;
  font-weight: 500;
}

.following-profile-card {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: var(--bg-light);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-soft);
  transition: transform var(--transition-fast);
}

.following-profile-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(0px);
  transition:
    background-color 0.4s ease,
    backdrop-filter 0.4s ease;
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content {
  background: white;
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  transform: translateY(50px) scale(0.95);
  opacity: 0;
  animation: slideUp 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.1s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(50px) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

/* Add closing animations */
.modal-overlay.closing {
  animation: fadeOut 0.3s forwards;
}

.modal-overlay.closing .modal-content {
  animation: slideDown 0.3s forwards;
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(30px) scale(0.95);
    opacity: 0;
  }
}

/* Add these new styles for the sidebar navigation */

.sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar-nav-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: var(--bg-light);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-soft);
}

.sidebar-nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background-color: var(--bg-medium);
  color: var(--text-medium);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all var(--transition-fast);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sidebar-nav-button:hover {
  background-color: var(--primary-light);
  color: var(--primary-color);
  transform: translateY(-2px);
}

.sidebar-nav-button:active {
  transform: translateY(0) scale(0.98);
}

.sidebar-nav-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.7) 10%,
    transparent 10.01%
  );
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition:
    transform 0.5s,
    opacity 1s;
}

.sidebar-nav-button:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* Match edit profile button style */
.edit-profile-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding: 10px 16px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all var(--transition-fast);
  width: 100%;
  box-shadow: 0 4px 10px rgba(255, 152, 0, 0.2);
}

.edit-profile-btn:hover {
  background-color: var(--accent-hover);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 152, 0, 0.3);
}

.edit-profile-btn:active {
  transform: translateY(0) scale(0.98);
}

/* Remove the old button styling if it exists */
.go-to-company-btn {
  /* You can remove this class or leave it empty if you need to keep the class for other purposes */
}

/* Ensure the profile card connects well with the new container */
.sidebar-profile-card {
  margin-bottom: 0 !important;
  padding: 15px;
  background: var(--bg-light);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-soft);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition:
    transform var(--transition-medium),
    box-shadow var(--transition-medium);
}

.sidebar-profile-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-medium);
}

.sidebar-profile-card img {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
  border: 3px solid var(--primary-light);
  margin-bottom: 15px;
  transition: transform var(--transition-medium);
}

.sidebar-profile-card:hover img {
  transform: scale(1.05);
}

.sidebar-profile-card h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 4px;
}

.sidebar-profile-card p {
  font-size: 0.9rem;
  color: var(--text-medium);
  margin-bottom: 2px;
}

/* Add this CSS to fix the sidebar profile card display */

/* Ensure sidebar profile cards have specific styling */
.sidebar .profile-card,
.sidebar-profile-card {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.sidebar .profile-card .profile-card-image,
.sidebar-profile-card .profile-card-image {
  width: 90px;
  height: 90px;
  margin-right: 0;
  margin-bottom: 15px;
}

.sidebar .profile-card .profile-details,
.sidebar-profile-card .profile-details {
  text-align: center;
  margin-bottom: 15px;
}

.sidebar .profile-card .profile-actions,
.sidebar-profile-card .profile-actions {
  justify-content: center;
  width: 100%;
}

.sidebar .edit-profile-btn,
.sidebar-profile-card .edit-profile-btn {
  width: 100%;
}

/* Direct profile styling in sidebar */
.sidebar-profile-card {
  background-color: var(--bg-light);
  border-radius: var(--border-radius-sm);
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: var(--shadow-soft);
  transition:
    transform var(--transition-fast),
    box-shadow var(--transition-medium);
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sidebar-profile-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-medium);
}

.sidebar-profile-card .profile-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.sidebar-profile-card .profile-card-image {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
  border: 3px solid var(--primary-light);
  transition: transform var(--transition-medium);
}

.sidebar-profile-card:hover .profile-card-image {
  transform: scale(1.05);
}

.sidebar-profile-card .profile-details {
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
}

.sidebar-profile-card .profile-actions {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar-nav-container,
  .sidebar-profile-card {
    padding: 15px;
  }

  .sidebar-nav-button {
    padding: 10px 14px;
    font-size: 0.9rem;
  }

  .edit-profile-btn {
    padding: 8px 14px;
  }
}
