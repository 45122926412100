.create-post-form {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  overflow: hidden;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e0e0e0;
}

/* Collapsed state */
.create-post-form.collapsed {
  padding: 0;
  min-height: 52px;
}

/* Expanded state */
.create-post-form.expanded {
  padding: 16px;
}

/* Create post button */
.create-post-toggle-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 52px;
  border: none;
  background-color: transparent;
  padding: 0 16px;
  cursor: pointer;
  color: #558392;
  font-weight: 500;
  font-size: 0.95rem;
  text-align: left;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.create-post-toggle-btn:hover {
  background-color: rgba(85, 131, 146, 0.05);
}

.create-post-toggle-btn svg {
  color: #558392;
}

.create-post-textarea {
  width: 100%;
  min-height: 50px; /* Reduced from 100px */
  height: 50px; /* Default height for ~2 lines */
  max-height: 300px; /* Maximum height when expanded */
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
  resize: vertical;
  overflow-y: auto; /* Add scrollbar when content exceeds height */
  line-height: 1.5; /* Better line spacing */
  transition: height 0.3s ease;
}

.create-post-textarea:focus {
  outline: none;
  border-color: #558392;
  box-shadow: 0 0 0 2px rgba(85, 131, 146, 0.1);
}

.button-container-icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.btn-icon {
  padding: 0.5rem;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  transition: all 0.2s ease;
  color: #666;
}

.btn-icon:hover {
  background: rgba(0, 0, 0, 0.1);
}

.btn-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cancel-button {
  color: #666;
}

.cancel-button:hover {
  color: #ff4444;
}

.file-name {
  font-size: 0.8rem;
  color: #666;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

/* Animation for expanding/collapsing */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.create-post-form.expanded .create-post-textarea,
.create-post-form.expanded .button-container-icons {
  animation: fadeIn 0.3s ease forwards;
}
