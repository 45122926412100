/* Bakgrunn */
.signupbackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}

/* SignUp Container */
.signUp {
  margin: 50px auto;
  width: 90%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.2); /* Halvgjennomsiktig hvit bakgrunn */
  padding: 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Lysere kant for glass-effekt */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1); /* Myk skygge for dybde */
  backdrop-filter: blur(10px); /* Glass-effekt */
  -webkit-backdrop-filter: blur(10px); /* Safari-støtte */
  color: black;
}

.signUp h2 {
  text-align: center;
  color: #558392;
}

/* Input felt */
.signUp label {
  display: block;
  margin-top: 20px;
  color: #4f6f52; /* Mørk grønn */
}

.signUp input {
  margin-top: 5px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.6); /* Halvgjennomsiktig hvit bakgrunn */
}

.signUp input:focus {
  outline: none;
  border-color: #1a4d2e; /* Farge ved fokus */
}

/* Stilisering for gyldige/ugyldige felt */
input:valid {
  border-color: #ccc;
}

input:invalid {
  border-color: #f44336;
}

input[type='password']:focus {
  border-color: #2684ff;
}

/* Knapper */
.signUp button {
  margin-top: 20px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sign Up button */
.signUp button.btn-signup {
  background-color: orange;
  box-shadow: 0px 0px 10px rgba(249, 249, 247, 0.3);
}

.signUp button.btn-signup:hover {
  background-color: #607d8b;
}

/* Google og Microsoft knapper */
.socioalSignIn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px; /* Add space below the buttons */
}

.socioalSignIn button {
  width: 48%;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

/* Google button */
.socioalSignIn .btn-google {
  background-color: lightblue;
}

.socioalSignIn .btn-google img,
.socioalSignIn .btn-microsoft img {
  margin-right: 8px;
}

.socioalSignIn .btn-google:hover {
  background-color: #558392;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

/* Microsoft button */
.socioalSignIn .btn-microsoft {
  background-color: #f3f3f3;
}

.socioalSignIn .btn-microsoft:hover {
  background-color: #558392;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

/* Feilmelding og meldinger */
.errorText {
  margin-top: 10px;
  color: #d9534f;
  text-align: center;
}

.messageText {
  margin-top: 20px;
  text-align: center;
  color: #333;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
  word-break: break-word;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageText.error {
  border-left-color: #f44336;
}

/* Divider */
.divider {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.divider::before,
.divider::after {
  content: '';
  flex-grow: 1;
  border-top: 1px solid #ddd;
}

.divider span {
  padding: 0 10px;
  font-size: 14px;
  color: #888;
}

/* Magic Link Wrapper 
.magicLinkWrapper {
  margin-top: 20px;
  text-align: center;
  position: relative;
}*/

/* Info button og tooltip styling 
.magic-link-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 20px;
}*/

.info-icon {
  cursor: pointer;
  color: #558392;
  font-size: 18px;
  padding: 2px 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.info-icon:hover {
  color: #1a4d2e;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  top: -120px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.95);
  color: #333;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 250px;
  font-size: 14px;
  line-height: 1.4;
  z-index: 100;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.info-icon:hover + .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip ul {
  margin: 5px 0 0 0;
  padding-left: 20px;
  text-align: left;
}

.tooltip li {
  margin-bottom: 5px;
}

/* Login description styling */
.login-description {
  display: none;
}

.login-description p {
  margin: 10px 0;
}

/* Fix the link button color for better visibility */
.link-button {
  background: none;
  border: none;
  color: #000000 !important; /* Changed to black as requested */
  cursor: pointer;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 5px;
  text-decoration: underline;
  font-weight: 500;
  transition: color 0.3s ease;
}

.link-button:hover {
  color: orange !important; /* Keeping the hover state orange */
}

/* Toggle button for creating a new account */
.toggle-auth-method {
  margin-top: 15px;
  text-align: center;
}

.toggle-auth-method {
  margin-top: 15px;
  text-align: center;
}

/* Oppdatert login-description-header uten boks */
.login-description-header {
  width: 90%;
  max-width: 600px;
  margin: 0 auto 30px auto;
  text-align: center;
  color: #4f6f52;
  padding: 0 20px;
}

.login-description-header p {
  margin: 0;
  font-size: 1.05rem;
  line-height: 1.6;
  font-weight: 500;
  text-shadow: 0 1px 3px rgba(255, 255, 255, 0.7);
}

/* Magic Link button */
.btn-magic-link,
.magic-link-container,
.magic-link-wrapper,
.magic-link-container form,
.magicLinkWrapper {
  display: none !important;
}

/* Custom Magic Link button */
.signUp .custom-magic-link-btn {
  /* Let the styles from MagicLinkBtn.css take precedence */
  background: none;
  border: none;
  box-shadow: none;
  margin: 20px 0;
  padding: 0;
  width: 100%;
}

/* Password container for positioning forgot password button */
.password-container {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

/* Legg til disse CSS-reglene for å øke sikkerhet og brukervennlighet */
.password-requirements {
  font-size: 0.8rem;
  color: #666;
  margin-top: 4px;
  margin-bottom: 16px;
}

/* Forgot password button styling */
.forgot-password-btn {
  background: none !important;
  border: none !important;
  color: #558392 !important;
  cursor: pointer;
  font-size: 0.8rem !important;
  padding: 0 !important;
  margin: 5px 0 0 0 !important;
  text-decoration: underline;
  text-align: right !important;
  display: block !important;
  width: auto !important;
  margin-left: auto !important;
}

.forgot-password-btn:hover {
  color: #1a4d2e !important;
  transform: translateY(-1px);
  transition: transform 0.2s;
}

.forgot-password-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Hover-stil for å unngå klikk-jacking */
.btn:hover {
  transform: translateY(-1px);
  transition: transform 0.2s;
}

/* Venting-tilstand for å forhindre dobbelttrykk */
.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Skjul UI elementer på auth handler-siden */
body[class*='auth-handler'] .navbar,
body[class*='auth-handler'] header,
body[class*='auth-handler'] footer {
  display: none !important;
}
