:root {
  --bar-color-dark: #616161;
  --bar-stroke-dark: #c6ccd2;
  --border-color-dark: #616161;
  --light-bg-dark: #3e3e3e;
  --light-border-color-dark: #3e3e3e;
  --text-muted-dark: #eee;
  --text-light-dark: #ececec;
  --text-color-dark: #f7f7f7;
  --blue-dark: #8a8aff;
}
.dark > .gantt-container .gantt .grid-row {
  fill: #252525;
}
.dark > .gantt-container .gantt .row-line {
  stroke: var(--light-border-color-dark);
}
.dark > .gantt-container .gantt .tick {
  stroke: var(--border-color-dark);
}
.dark > .gantt-container .gantt .holiday-highlight {
  fill: var(--light-bg-dark);
}
.dark > .gantt-container .gantt .arrow {
  stroke: var(--text-muted-dark);
}
.dark > .gantt-container .gantt .bar {
  fill: var(--bar-color-dark);
  stroke: none;
}
.dark > .gantt-container .gantt .bar-progress {
  fill: var(--blue-dark);
}
.dark > .gantt-container .gantt .bar-invalid {
  fill: transparent;
  stroke: var(--bar-stroke-dark);
}
:is(.dark > .gantt-container .gantt .bar-invalid) ~ .bar-label {
  fill: var(--text-light-dark);
}
.dark > .gantt-container .gantt .bar-label.big {
  fill: var(--text-light-dark);
}
.dark > .gantt-container .gantt .bar-wrapper:hover .bar {
  fill: lighten(var(--bar-color-dark, 5));
}
.dark > .gantt-container .gantt .bar-wrapper:hover .bar-progress {
  fill: lighten(var(--blue-dark, 5));
}
.dark > .gantt-container .gantt .bar-wrapper.active .bar {
  fill: lighten(var(--bar-color-dark, 5));
}
.dark > .gantt-container .gantt .bar-wrapper.active .bar-progress {
  fill: lighten(var(--blue-dark, 5));
}
.dark > .gantt-container .grid-header {
  background-color: #252525;
}
.dark > .gantt-container .popup-wrapper {
  background-color: #333;
}
.dark > .gantt-container .popup-wrapper .title {
  border-color: lighten(var(--blue-dark, 5));
}
.dark > .gantt-container .popup-wrapper .pointer {
  border-top-color: #333;
}
:root {
  --bar-color: orange;
  --bar-color-important: #94c4f4;
  --bar-stroke: #fff;
  --dark-stroke-color: #e0e0e0;
  --stroke-color: #ebeef0;
  --light-bg: #f5f5f5;
  --light-border-color: #ebeff2;
  --light-yellow: #f6e796;
  --holiday-color: #f9fafa;
  --text-muted: #666;
  --text-grey: #98a1a9;
  --text-light: #fff;
  --text-dark: #111;
  --progress: #ebeef0;
  --handle-color: #dcdce4;
  --handle-color-important: #94c4f4;
  --light-blue: #c4c4e9;
  --middle-blue: #62b2f9;
  --dark-blue: #2c94ec;
}
.gantt-container {
  line-height: 14.5px;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  height: calc(100vh - 250px);
  min-height: 400px;
}
.gantt-container .popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: #171b1f;
  padding: 10px;
  border-radius: 5px;
  width: max-content;
}
.gantt-container .popup-wrapper.hidden {
  opacity: 0 !important;
}
.gantt-container .popup-wrapper .title {
  margin-bottom: 5px;
  text-align: -webkit-center;
  text-align: center;
  color: var(--text-light);
}
.gantt-container .popup-wrapper .subtitle {
  color: var(--text-grey);
}
.gantt-container .popup-wrapper .pointer {
  position: absolute;
  height: 5px;
  margin: 0 0 0 -5px;
  border: 5px solid transparent;
  border-bottom-color: #000c;
}
.gantt-container .grid-header {
  background-color: #f8f9fa; /* Match table header background color */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 2px solid #e9ecef; /* Match table header border color */
}
.gantt-container .lower-text,
.gantt-container .upper-text {
  text-anchor: middle;
  color: #558392; /* Match table header text color */
  font-size: 13px; /* Match table header font size */
  font-weight: 600; /* Match table header font weight */
}
.gantt-container .today-button {
  background-color: #607d8b !important; /* Match header background color */
  color: white !important; /* Set button text color */
  border: none;
  padding: 4px 6px;
  margin: 0px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
.gantt-container .today-button:hover {
  background-color: #007078;
  color: white !important;
}
.gantt-container .current-date-highlight {
  background: #007078; /* Set highlight color */
  color: var(--text-light);
  padding: 4px 8px;
  border-radius: 200px;
}
.gantt-container .upper-header {
  height: 40px;
}
.gantt-container .lower-header {
  height: 30px;
}
.gantt-container .lower-text {
  font-size: 14px;
  position: absolute;
  width: fit-content;
  transform: translate(-50%);
}
.gantt-container .upper-text {
  position: absolute;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
}
.gantt-container .current-upper {
  position: fixed;
}
.gantt-container .side-header {
  position: fixed;
  padding: 0 10px;
  margin-right: 10px;
  background: #fff;
  line-height: 20px;
  font-weight: 400;
  background-color: #f8f9fa;
}
.gantt-container .today-button,
.gantt-container .viewmode-select {
  background: #f4f5f6;
  text-align: -webkit-center;
  text-align: center;
  border: none;
  color: var(--text-dark);
  padding: 4px 10px;
  border-radius: 8px;
  height: 25px;
}
.gantt-container .viewmode-select {
  outline: none !important;
  padding: 4px 8px;
  margin-right: 4px;
  text-indent: 1px;
  text-overflow: '';
}
.gantt-container .date-highlight {
  background-color: var(--progress);
  border-radius: 12px;
  position: absolute;
  display: none;
}
.gantt-container .current-highlight {
  position: absolute;
  background: var(--dark-blue);
  width: 1px;
}
.gantt-container .current-date-highlight {
  background: var(--dark-blue);
  color: var(--text-light);
  padding: 4px 8px;
  border-radius: 200px;
}
.gantt {
  user-select: none;
  -webkit-user-select: none;
  position: absolute;
}
.gantt .grid-background {
  fill: none;
}
.gantt .grid-row {
  fill: #fff;
}
.gantt .row-line {
  stroke: var(--light-border-color);
}
.gantt .tick {
  stroke: var(--stroke-color);
  stroke-width: 0.4;
}
.gantt .tick.thick {
  stroke: var(--dark-stroke-color);
  stroke-width: 0.7;
}
.gantt .holiday-highlight {
  fill: var(--holiday-color);
}
.gantt .arrow {
  fill: none;
  stroke: #9fa9b1;
  stroke-width: 1;
}
.gantt .bar-wrapper .bar {
  fill: var(--bar-color);
  stroke: var(--bar-stroke);
  stroke-width: 0;
  transition: stroke-width 0.3s ease;
}
.gantt .bar-progress {
  fill: var(--progress);
}
.gantt .bar-expected-progress {
  fill: var(--light-blue);
}
.gantt .bar-invalid {
  fill: transparent;
  stroke: var(--bar-stroke);
  stroke-width: 1;
  stroke-dasharray: 5;
}
:is(.gantt .bar-invalid) ~ .bar-label {
  fill: var(--text-light);
}
.gantt .bar-label {
  fill: var(--text-dark);
  dominant-baseline: central;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: 400;
}
.gantt .bar-label.big {
  fill: var(--text-dark);
  text-anchor: start;
}
.gantt .bar-wrapper.important .bar {
  fill: var(--bar-color-important);
}
.gantt .bar-wrapper.important .bar-progress {
  fill: var(--dark-blue);
}
.gantt .bar-wrapper.important .bar-label {
  fill: var(--text-light);
}
.gantt .bar-wrapper.important .handle {
  fill: var(--handle-color-important);
}
.gantt .bar-wrapper.important .handle.progress {
  fill: var(--text-light);
}
.gantt .handle {
  fill: var(--handle-color);
  cursor: ew-resize;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
}
.gantt .handle.progress {
  fill: var(--text-muted);
}
.gantt .bar-wrapper {
  cursor: pointer;
}
.gantt .bar-wrapper.active .handle {
  visibility: visible;
  opacity: 1;
}
.gantt .bar-wrapper .bar {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0 0 2px rgba(17, 43, 66, 0.16));
  border-radius: 3px;
}
.gantt .bar-wrapper .bar.safari {
  outline: 1px solid black;
}
.gantt .bar-wrapper:hover .bar {
  transition: transform 0.3s ease;
}
.gantt .bar-wrapper:hover .date-highlight {
  display: block;
}
.gantt .hide {
  display: none;
}
